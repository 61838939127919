<div class="dialog_view">
    <div class="dialog_window">
      <button class="dialog_window_btn_close" (click)="close()"><img src="../../../../assets/negativewhite.svg"></button>
      <div>
        <div class="dialog_window_header">
          <h2>Zuordnung</h2>
        </div>
        <div class="dialog_window_body">
          <div class="checkbox_wrapper" (click)="selectionClassification(1)">
            <input type="checkbox" class="checkbox" [checked]="selectedClassification.includes(1)">
            <label>Waymark</label>
          </div>
          <div class="checkbox_wrapper" (click)="selectionClassification(2)">
            <input type="checkbox" class="checkbox" [checked]="selectedClassification.includes(2)">
            <label>Zweispurig</label>
          </div>
          <div class="checkbox_wrapper" (click)="selectionClassification(3)">
            <input type="checkbox" class="checkbox" [checked]="selectedClassification.includes(3)">
            <label>Autobase</label>
          </div>
          <div class="checkbox_wrapper" (click)="selectionClassification(4)">
            <input type="checkbox" class="checkbox" [checked]="selectedClassification.includes(4)">
            <label>Autoscout</label>
          </div>
        </div>
      </div>
      <button class="dialog_window_btn_submit" (click)="applyFilter()">Filtern</button>
    </div>
  </div>