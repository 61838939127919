<form *ngIf="form" class="view_body_create" [formGroup]="form">
  <div id="phone_create_header">
    <h1>{{this.call ? 'Anruf bearbeiten' : 'Anruf erstellen'}}</h1>
      <div id="phone_create_button_wrapper">
        <button class="btn_cancel" (click)="selectionCancel()">Abbrechen</button>
        <button *ngIf="!call" type="button" class="btn_tab btn_tab_blue_light" (click)="selectionCreate()">Erstellen</button>
        <button *ngIf="call" type="button" class="btn_tab btn_tab_blue_light" (click)="selectionUpdate()">Speichern</button>
      </div>
  </div>

  <div id="phone_create_container">
    <div class="column">
      <div class="data_box">
        <div class="data_box_package">
          <h2>Kontakt</h2>
          <label class="lbl_regular_12">Kunde</label>
          <div [ngClass]="form.get('customer_id').value ? 'trash-icon-input' : 'input_wrapper'">
            <img *ngIf="form.get('customer_id').value" class="icon_contact_remove" src="./assets/delete.svg"  (click)="selectionClearCustomer(true)">
            <div class="dropdown_wrapper" (click)="showDropdown = !showDropdown" appDropdown [show]="showDropdown" (changedValueEmitter)="showDropdown = $event">
              <input #inputCustomer autocomplete="off" id="input-customer" placeholder="---" (input)="searchCustomers(inputCustomer.value)">
              <img class="dropdown_icon" [ngClass]="showDropdown ? 'dropdown_icon_open' : ''" src="./assets/arrowdropdown.svg">
              <div class="dropdown_value_container" *ngIf="showDropdown">
                <div *ngFor="let customer of customersFiltered">
                  <label
                    (click)="selectionCustomer(customer.customer_id);" class="dropdown_element lbl_dropdown_small">
                      {{customer.company_name}}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="input_wrapper" [class.input-wrapper-disabled]="form.get('customer_id').value == null">
            <label>Kontaktperson</label>
            <div [ngClass]="form.get('contact_person_id').value ? 'trash-icon-input' : 'input_wrapper'">
              <img *ngIf="form.get('contact_person_id').value" class="icon_contact_remove" src="./assets/delete.svg" (click)="selectionClearContact()">
              <div class="dropdown_wrapper" (click)="showDropdown2 = !showDropdown2" appDropdown [show]="showDropdown2" (changedValueEmitter)="showDropdown2 = $event">
                <input #inputContact autocomplete="off" placeholder="---" id="input-contact" readonly>
                <img class="dropdown_icon" *ngIf="form.get('customer').value" [ngClass]="showDropdown2 ? 'dropdown_icon_open' : ''" src="./assets/arrowdropdown.svg">
                <div class="dropdown_value_container" *ngIf="showDropdown2 && form.get('customer').value">
                    <div *ngFor="let con of selectedCustomerContacts">
                      <label *ngIf="form.get('customer').value" (click)="selectionContact(con.contact_id);" class="dropdown_element lbl_dropdown_small">
                        {{con.firstName}} {{con.lastName}}</label>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="data_box_package border-bottom-none">
          <h2>Kontaktdaten</h2>
          <div id="form-contact-wrapper">
              <form class="contact-form" [formGroup]="formContact">
                <div class="input_wrapper">
                  <label>{{form.get('is_contact_to_create').value && form.get('customer_id').value != null ?  'Geschlecht*' : 'Geschlecht'}}</label>
                  <div class="dropdown_wrapper" (click)="showDropdown4 = !showDropdown4" appDropdown [show]="showDropdown4 && !form.get('contact_person_id').value" (changedValueEmitter)="showDropdown4 = $event">
                    <input #inputSalutation placeholder="---" [value]="formContact.get('contact_salutation').value == null ? '---' : (formContact.get('contact_salutation').value == false ? 'Frau' : 'Herr')" data-readonly [class.input_invalid]="submitted && formContact.controls['contact_salutation'].invalid">
                    <img class="dropdown_icon" [ngClass]="showDropdown4 ? 'dropdown_icon_open' : ''" src="./assets/arrowdropdown.svg">
                    <div class="dropdown_value_container" *ngIf="showDropdown4">
                      <label (click)="formContact.get('contact_salutation').setValue(false)" class="dropdown_element lbl_dropdown_small">Frau</label>
                      <label (click)="formContact.get('contact_salutation').setValue(true)" class="dropdown_element lbl_dropdown_small">Herr</label>
                    </div>
                  </div>
                </div>
                <div class="input_wrapper">
                  <label>{{form.get('is_contact_to_create').value && form.get('customer_id').value != null  ?  'Vorname*' : 'Vorname'}}</label>
                  <input type="text" placeholder="---" formControlName="contact_first_name" [class.input_invalid]="submitted && formContact.controls['contact_first_name'].invalid">
                </div>
                <div class="input_wrapper">
                  <label>{{form.get('is_contact_to_create').value && form.get('customer_id').value != null  ?  'Nachname*' : 'Nachname'}}</label>
                  <input type="text" placeholder="---" formControlName="contact_last_name" [class.input_invalid]="submitted && formContact.controls['contact_last_name'].invalid">
                </div>
                <div class="input_wrapper">
                  <label>Firma</label>
                  <input type="text" placeholder="---" formControlName="company_name">
                </div>
                <div class="input_wrapper">
                  <label>Telefonnummer*</label>
                  <input type="text" placeholder="---" formControlName="contact_phone" [class.input_invalid]="submitted && formContact.controls['contact_phone'].invalid">
                </div>
                <div class="input_wrapper">
                  <label>{{form.get('is_contact_to_create').value && form.get('customer_id').value != null ?  'E-Mail-Adresse*' : 'E-Mail-Adresse'}}</label>
                  <input type="text" placeholder="---" formControlName="contact_email" [class.input_invalid]="submitted && formContact.controls['contact_email'].invalid">
                </div>
                <div class="input_wrapper">
                  <label>Position</label>
                  <input type="text" placeholder="---" formControlName="position">
                </div>
              </form>
              <div class="flex_slider" *ngIf="form.get('customer_id').value">
                <div class="slider_container" (click)="selectionContactToCreate()">
                  <div class="slider_still" [ngClass]="form.get('is_contact_to_create').value ? '' : 'slider_still_on'"></div>
                  <div class="slider_move" [ngClass]="form.get('is_contact_to_create').value  ? 'slider_on' : 'slider_off'"></div>
                </div>
                <div [ngClass]="form.get('is_contact_to_create').value ? 'lbl_regular_12' : 'lbl_regular_12_inactive'" style="margin-bottom: 0;">Als Kontaktperson speichern</div>
            </div>
          </div>
          <!-- <div id="form-contact-wrapper">
              <form class="contact-form" [formGroup]="formContact">
                <div class="input_wrapper">
                <label [ngClass]="form.get('contact_person_id').value ? 'edit' : ''">Geschlecht</label>
                <div *ngIf="!form.get('contact_person_id').value" class="dropdown_wrapper" (click)="showDropdown4 = !showDropdown4" appDropdown [show]="showDropdown4 && !form.get('contact_person_id').value" (changedValueEmitter)="showDropdown4 = $event">
                  <input #inputSalutation placeholder="---" formControlName="contact_salutation" data-readonly [required]="form.get('is_contact_to_create').value && submitted && formContact.controls['contact_salutation'].invalid">
                  <img class="dropdown_icon" [ngClass]="showDropdown4 ? 'dropdown_icon_open' : ''" src="./assets/arrowdropdown.svg">
                  <div class="dropdown_value_container" *ngIf="showDropdown4">
                    <label (click)="formContact.get('contact_salutation').setValue(false); this.form.get('gender').setValue('Frau'); inputSalutation.value = 'Frau'" class="dropdown_element lbl_dropdown_small">Frau</label>
                    <label (click)="formContact.get('contact_salutation').setValue(true); this.form.get('gender').setValue('Herr'); inputSalutation.value = 'Herr'" class="dropdown_element lbl_dropdown_small">Herr</label>
                  </div>
                </div>
                <label *ngIf="form.get('contact_person_id').value && (formContact.get('contact_salutation').value == 0 || this.form.get('gender').value === 'Frau')" class="input-not-editable" >Frau</label>
                <label *ngIf="form.get('contact_person_id').value && (formContact.get('contact_salutation').value == 1 || this.form.get('gender').value === 'Herr')" class="input-not-editable" >Herr</label>
                </div>
                <div class="input_wrapper">
                  <label [ngClass]="form.get('contact_person_id').value ? 'edit' : ''">Vorname</label>
                  <input *ngIf="!form.get('contact_person_id').value" type="text" placeholder="---" formControlName="contact_first_name" [required]="form.get('is_contact_to_create').value && submitted && formContact.controls['contact_first_name'].invalid">
                  <label class="input-not-editable" *ngIf="form.get('contact_person_id').value">{{ form.get('contact_first_name').value }}</label>
                </div>
                <div class="input_wrapper">
                  <label [ngClass]="form.get('contact_person_id').value ? 'edit' : ''">Nachname</label>
                  <input *ngIf="!form.get('contact_person_id').value" type="text" placeholder="---" formControlName="contact_last_name" [required]="form.get('is_contact_to_create').value && submitted && formContact.controls['contact_last_name'].invalid">
                  <label class="input-not-editable" *ngIf="form.get('contact_person_id').value">{{ form.get('contact_last_name').value }}</label>
                </div>
              </form>
              <div class="input_wrapper">
                <label [ngClass]="form.get('contact_person_id').value ? 'edit' : ''">Firma</label>
                <input *ngIf="!form.get('contact_person_id').value" type="text" placeholder="---" formControlName="company_name" [required]="form.get('is_contact_to_create').value && submitted && form.controls['company_name'].invalid">
                <label class="input-not-editable" *ngIf="form.get('contact_person_id').value">{{ form.get('company_name').value }}</label>
              </div>
              <form class="contact-form" [formGroup]="formContact">
                <div class="input_wrapper">
                  <label [ngClass]="form.get('contact_person_id').value ? 'edit' : ''">Telefonnummer*</label>
                  <input *ngIf="!form.get('contact_person_id').value && !form.get('phone').value" type="text" placeholder="---" formControlName="contact_phone" [class.input_invalid]="submitted && formContact.controls['contact_phone'].invalid">
                  <label class="input-not-editable" *ngIf="form.get('phone').value">{{ form.get('phone').value }}</label>
                </div>
                <div class="input_wrapper">
                  <label [ngClass]="form.get('contact_person_id').value ? 'edit' : ''">E-Mail-Adresse</label>
                  <input *ngIf="!form.get('contact_person_id').value" type="text" placeholder="---" formControlName="contact_email" [required]="form.get('is_contact_to_create').value && submitted && formContact.controls['contact_email'].invalid">
                  <label class="input-not-editable" *ngIf="form.get('contact_person_id').value && form.get('contact_email').value">{{ form.get('contact_email').value }}</label>
                </div>
                <div class="input_wrapper">
                  <label [ngClass]="form.get('contact_person_id').value ? 'edit' : ''">Position</label>
                  <input *ngIf="!form.get('contact_person_id').value" type="text" placeholder="---" formControlName="position">
                  <label class="input-not-editable" *ngIf="form.get('contact_person_id').value">{{ form.get('contact_position').value ? form.get('contact_position').value : '---'}}</label>
                </div>
              </form>
          </div>
          <div class="flex_slider">
              <div class="slider_container" (click)="this.form.get('is_contact_to_create').setValue(!this.form.get('is_contact_to_create').value);">
                <div class="slider_still" [ngClass]="form.get('is_contact_to_create').value && form.get('company_name').value ? '' : 'slider_still_on'"></div>
                <div class="slider_move" [ngClass]="form.get('is_contact_to_create').value && form.get('company_name').value  ? 'slider_on' : 'slider_off'"></div>
              </div>
              <div [ngClass]="form.get('is_contact_to_create').value && form.get('company_name').value ? 'lbl_regular_12' : 'lbl_regular_12_inactive'" style="margin-bottom: 0;">Als Kontaktperson speichern</div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="column">
      <div class="data_box">
        <div class="data_box_package border-bottom-none">
          <h2>Planung</h2>
          <div class="input_wrapper_planning">
            <label class="lbl_regular_12">Datum*</label>
            <input #inputDate1
              type="date"
              id="input-due-date"
              [min]="minDate"
              formControlName="due_date"
              [defaultValue]="form.get('due_date').value"
              [class.date-input--has-value]="inputDate1.value != null"
              [class.input_invalid]="submitted && form.controls['due_date'].errors">
          </div>
          <div class="input_wrapper_planning">
          <div class="label_with_toggle_button">
            <label class="lbl_regular_12">Zeit</label>
            <div class="flex_slider_label" style="padding-bottom: 4px;">
              <div class="slider_container" (click)="selectionTimeTypExact()">
                <div class="slider_still" [ngClass]="form.get('time_type').value == TimeTyp.EXACT ? '' : 'slider_still_on'"></div>
                <div class="slider_move" [ngClass]="form.get('time_type').value == TimeTyp.EXACT ? 'slider_on' : 'slider_off'"></div>
              </div>
            <div [ngClass]="form.get('time_type').value == TimeTyp.EXACT ? 'lbl_regular_12' : 'lbl_regular_12_inactive'" style="margin-bottom: 0px;">Individuelle Zeit definieren</div>
            </div>
          </div>
          <div *ngIf="this.form.get('time_type').value != TimeTyp.EXACT" class="dropdown_wrapper" (click)="showDropdown3 = !showDropdown3" appDropdown [show]="showDropdown3" (changedValueEmitter)="showDropdown3 = $event">
            <input #inputTime placeholder="---" [value]="form.get('time_type').value == TimeTyp.MORNING ? 'Vormittag' : 'Nachmittag'" readonly>
            <img class="dropdown_icon" [ngClass]="showDropdown3 ? 'dropdown_icon_open' : ''" src="./assets/arrowdropdown.svg">
            <div class="dropdown_value_container" *ngIf="showDropdown3">
              <label (click)="setTimeTyp('morning')" class="dropdown_element lbl_dropdown_small">Vormittag</label>
              <label (click)="setTimeTyp('afternoon')" class="dropdown_element lbl_dropdown_small">Nachmittag</label>
            </div>
          </div>
          <div *ngIf="this.form.get('time_type').value == TimeTyp.EXACT" class="input_wrapper">
            <input #inputDate2 type="time" placeholder="---" formControlName="time">
          </div>
        </div>
          <div class="input_wrapper_planning">
          <label class="lbl_regular_12">Priorität</label>
          <ul class="call-list">
            <li *ngFor="let type of selectPriorities">
              <button
                type="button"
                [ngClass]="{
                  'hoch': selectedPriority === 0 && type.value == selectedPriority,
                  'mittel': selectedPriority === 1 && type.value == selectedPriority,
                  'niedrig': selectedPriority === 2 && type.value == selectedPriority
                }"
                (click)="onSelectPriority(type)">
                <span class="name">{{ type.label }}</span>
              </button>
            </li>
          </ul>
        </div>
          <div class="input_wrapper_planning">
            <label class="lbl_regular_12">Kategorie</label>
            <ul class="call-list">
              <li *ngFor="let type of categories">
                <button
                  type="button"
                  [class.interessent]="type.label == selectedCategory"
                  (click)="onSelectCategory(type)">
                  <span class="name">{{ type.label }}</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
        <div class="data_box">
          <div class="data_box_package data_box_package_last">
            <h2>Anmerkung</h2>
              <label class="lbl_regular_12">
                Beim Anruf zu beachten
                <span>({{224-form.get('note').value?.length}} Zeichen übrig)</span>
                :
              </label>
              <textarea
                class="textarea-note"
                placeholder="---"
                maxlength="224"
                formControlName="note"
              ></textarea>
          </div>
        </div>
      </div>
  </div>
</form>


