import { formatDate } from "@angular/common";

export class DateHelper {

  public static getFormatDateFromTimestamp(timestamp: number): string {
    if(timestamp){
      return formatDate(new Date(timestamp), 'yyyy-MM-dd', 'de-at')
    }
    return null;
  }

  public static getFormatTimeFromTimestamp(timestamp: number): string {
    if(timestamp){
      return formatDate(new Date(timestamp), 'HH:mm', 'de-at')
    }
    return null;
  }

  public static getTimestampFromInputDate(date: string): number {
    if(date){
      let d = new Date(date);
      return d.getTime();
    } else return null;
  }
}
