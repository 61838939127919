import { ProductFactory } from "../../product/factory/ProductFactory";
import { ProductWaymarkFactory } from "../../product/factory/ProductWaymarkFactory";
import { ProductWaymark } from "../../product/ProductWaymark";
import { SectionFactory } from "../../project/factory/SectionFactory";
import { BillngAddressFactory } from "../BillngAddress";
import { Customer } from "../Customer";
import { AddressFactory } from "./AddressFactory";
import { ContactPersonFactory } from "./ContactPersonFactory";
import { SearchCredentialsFactory } from "./SearchCredentialsFactory";

export class CustomerFactory {
    static jsonFactory(rawBody: any): Customer[] {
        const customers: Customer[] = [];
        for(let rawCustomer of rawBody){
            customers.push(this.jsonFactoryOne(rawCustomer));
        }
        return customers;
    }

    static jsonFactoryOne(rawCustomer: any): Customer {
        const customer = new Customer(
            rawCustomer.customer_id,
            rawCustomer.created_from,
            rawCustomer.has_dataprivacy,
            rawCustomer.seller_id,
            rawCustomer.company_name,
            rawCustomer.billing_address ? BillngAddressFactory.jsonFactoryOne(rawCustomer.billing_address) : null,
            ContactPersonFactory.jsonFactory(rawCustomer.contact_persons),
            rawCustomer.typ,
            rawCustomer.has_rating,
            rawCustomer.website_url,
            rawCustomer.last_appointment && (rawCustomer.last_appointment[0] != null) ? rawCustomer.last_appointment : [],
            rawCustomer.sections ? SectionFactory.jsonFactory(rawCustomer.sections) : [],
            rawCustomer.is_waymark,
            rawCustomer.is_autobase,
            rawCustomer.is_zweispurig,
            rawCustomer.is_autoscout,
            rawCustomer.search_terms,
            rawCustomer.creation_date,
        );
        return customer;
    }
}
