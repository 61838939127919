<div class="dialog_view">
    <div class="dialog_window">
        <button class="dialog_window_btn_close" (click)="close()"><img src="../../../../assets/negativewhite.svg"></button>
        <div>
            <div class="dialog_window_header">
                <h2>Passwort ändern</h2>
            </div>
            <div class="dialog_window_body">
                <form [formGroup]="form">
                  <div class="input_wrapper">
                    <label id="old_password">Altes Passwort</label>
                    <div class="input_cover">
                      <input formControlName="old_password" type="{{showPassword ? 'text' : 'password'}}" [required]="submitted && form.controls.old_password.invalid">
                      <img class="icon_password_show_hide" (click)="tooglePassword()" src="{{ showPassword ? showInputImage : hideInputImage}}">
                    </div>
                  </div>
                  
                  <div class="input_wrapper">
                    <label id="new_password">Neues Passwort</label>
                    <div class="input_cover">
                      <input formControlName="new_password" type="{{showPasswordNew ? 'text' : 'password'}}" [class.is-invalid]="submitted && form.controls.new_password.invalid">
                      <img class="icon_password_show_hide" (click)="showPasswordNew = !showPasswordNew" src="{{ showPasswordNew ? showInputImage : hideInputImage}}">
                    </div>
                    <div class="new_password_error_text" *ngIf="submitted && (form.controls.new_password.hasError('passwordStrength') || form.invalid)">{{ form.controls.new_password?.getError('passwordStrength') }}</div>
                  </div>
                  
                </form>
              </div>
        </div>
        <button class="dialog_window_btn_submit" (click)="submitFilterForm()">Passwort speichern</button>
    </div>
</div>
  
