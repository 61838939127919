import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Call, TIMETYPE } from "../Call";
import { CustomerForm } from "../../customer/form/CustomerForm";
import { DateHelper } from "src/app/utils/DateHelper";

export class CallForm {

  public static getForm(formBuilder: FormBuilder, obj?: Call): FormGroup {
    let defaultDate = new Date();
    let defaultTimeTyp;
    if(defaultDate.getHours() < 12){
      defaultTimeTyp = TIMETYPE.MORNING;
    } else {
      defaultTimeTyp = TIMETYPE.AFTERNOON;
    }
    let form = formBuilder.group({
      call_id: [obj? obj.call_id : null],
      user_id: [obj? obj.user_id : null],
      category: [obj ? obj.category : "Interessent"],
      created: [obj ? obj.created : null],
      note: [obj ? obj.note : null],
      driver_note: [obj ? obj.driver_note : null],
      driver_mp3: [obj ? obj.driver_mp3 : null],
      due_date: [obj ?  DateHelper.getFormatDateFromTimestamp(obj.due_date) : DateHelper.getFormatDateFromTimestamp(defaultDate.getTime()), Validators.required],
      priority: [obj ? obj.priority : 1],
      status: [obj ? obj.status : null],
      customer_id: [obj ? obj.customer_id : null],
      contact_person_id: [obj ? obj.contact_person_id : null],

      customer: [obj && obj.customer ? obj.customer : null],
      contact_form: [CustomerForm.addContactForm(formBuilder)],
      is_contact_to_create: [false],
      time: [obj && obj.due_date ? DateHelper.getFormatTimeFromTimestamp(obj.due_date) : DateHelper.getFormatTimeFromTimestamp(defaultDate.getTime())],
      time_type: [obj ? obj.time_type : defaultTimeTyp],
      company_name: [obj && obj.company_name ? obj.company_name : null],
    })
    form.get('contact_form').value.get('contact_id').setValue(null);
    return form;
  }

  public static getObject(form: FormGroup): Call {
    const contact = CustomerForm.createContact(form.get('contact_form').value);
    let obj = new Call(
      form.get('call_id').value,
      form.get('user_id').value,
      contact.firstName,
      contact.lastName,
      contact.company_name,
      contact.phone,
      form.get('category').value,
      form.get('created').value,
      form.get('note').value,
      form.get('driver_note').value,
      form.get('driver_mp3').value,
      DateHelper.getTimestampFromInputDate(form.get('due_date').value),
      form.get('priority').value,
      form.get('status').value,
      null,
      form.get('customer_id').value,
      contact.contact_id,
      form.get('time_type').value,
    );
    obj.customer = form.get('customer').value;
    if(form.get('is_contact_to_create').value == false){
      obj.contact_email = contact.email;
      obj.contact_position = contact.position;
      obj.contact_salutation = contact.isMale;
    }
    return obj;
  }
}

export function phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
  const phoneNumberPattern = /^\s*[+]?\s*[0-9\s-]{6,17}\s*$/; //+43-676-4889066
  const isValid = phoneNumberPattern.test(control.value);
  return isValid ? null : { invalid: true };
}
