import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ThreadFactory } from 'src/app/buisness-object/thread/factory/ThreadFactory';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class ThreadService {
  public tickets$ = new BehaviorSubject<any>(null);
  private notificationTicketChange = new Subject<any>();
  public defaultFilter = {
    ticket_typ: [0, 1, 2, 3, 4, 5, 6],
    status: 1,
    only_subscribed: true
  };
  notificationTicketChange$ = this.notificationTicketChange.asObservable();

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
    ) { }

  getThreads(typ: number, id: number): Observable<Thread[]>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    let threadTyp = "";
    if(typ == 1) threadTyp = "customer/";
    else if(typ == 2) threadTyp = "project/";
    let start = new Date();
    console.log(`Request start tickets for ${threadTyp}...`);
    const observable = this.http.get(environment.api + '/back/thread/'+ threadTyp + id, { headers });
    return observable.pipe(
      map((rawThread: any) => {
        console.log(`Request time tickets for ${threadTyp}: ${(new Date().getTime()-start.getTime())/1000}`);
        const threads = ThreadFactory.jsonFactory(rawThread.threads);
        return threads;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  getAllThreads(reload?: boolean): Observable<Thread[]>{
    if(!reload && this.tickets$.getValue()){
      return of(this.tickets$.getValue());
    } else {
      const headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'JWT ' + localStorage.getItem('token'),
      });
      const observable = this.http.get(environment.api + '/back/thread/', { headers });
      return observable.pipe(
        map((rawThread: any) => {
          const threads = ThreadFactory.jsonFactory(rawThread.threads);
          this.tickets$.next(threads);
          return threads;
        }),
        catchError(error => {
          if(error.status == 403){
            this.lService.logout();
          } else {
            this.dService.handleError(error);
          }
          return [];
        })
      )
    }
  }

  createThread(thread: Thread): Observable<Thread>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    let body = thread.getJSON();
    const observable = this.http.post(environment.api + '/back/thread', body, { headers });
    return observable.pipe(
      map((rawThread: any) => {
        const thread = ThreadFactory.jsonFactoryOne(rawThread.thread);
        thread.subscribe = true;
        return thread;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  updateThread(thread: Thread): Observable<Thread>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    let body = thread.getJSON();
    // let messages = [];
    // thread.messages.forEach(msg => {
    //   messages.push({message: (msg.message ? msg.message : ""), timestamp: msg.timestamp});
    // });
    // let body = {
    //   thread: {
    //     thread_id: thread.threadId,
    //     customer_id: thread.customerId,
    //     user_id: thread.userId,
    //     typ: thread.type,
    //     status: thread.status,
    //     pinned: thread.pinned,
    //     notify_open: thread.notifyOpen,
    //     created: thread.createdDate,
    //     resolved: thread.resolved,
    //     resolved_by_user_id: thread.resolved_by_user_id,
    //     subject: thread.subject
    //   }
    // }
    const observable = this.http.put(environment.api + '/back/thread/' + thread.threadId, body, { headers });
    return observable.pipe(
      map((rawThread: any) => {
        const thread = ThreadFactory.jsonFactoryOne(rawThread.thread);
        return thread;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
        }
        return [];
      })
    )
  }

  transferThread(threadId: number, customer_id: number, project_id: number): Observable<Thread>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    let body = {
      customer_id: customer_id,
      project_id: project_id
    };
    const observable = this.http.put(environment.api + '/back/thread/transfer/' + threadId, body, { headers });
    return observable.pipe(
      map((rawThread: any) => {
        const thread = ThreadFactory.jsonFactoryOne(rawThread.thread);
        return thread;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
        }
        return [];
      })
    )
  }

  deleteThread(threadId: number): Observable<boolean>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.delete(environment.api + '/back/thread/' + threadId, { headers });
    return observable.pipe(
      map((rawThread: any) => {
        return true;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  getNotifications(): Observable<Thread[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
      'skip-loading-animation': 'true'
    });
    let start = new Date();
    console.log(`Request start ticket notifications...`);
    const observable = this.http.get(environment.api + '/back/thread/notification', { headers });
    return observable.pipe(
      map((rawThreads: any) => {
        console.log(`Request time ticket notifications: ${(new Date().getTime()-start.getTime())/1000}`);
        const threads = ThreadFactory.jsonFactory(rawThreads.threads);
        return threads;
      }),
      catchError(error => {
        if(error.status = 403){
          //this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  subscribe(thread_id: number): Observable<boolean>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.put(environment.api + '/back/thread/subscribe/' + thread_id,
    {}, { headers });
    return observable.pipe(
      map((raw: any) => {
        this.dService.showNotification({
          title: 'Ticket subscribed',
          message: 'Das Ticket wurde erfolgreich subscribed.',
          success: true
        });
        return true;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
        }
        return of(false);
      })
    )
  }

  unsubscribe(thread_id: number): Observable<boolean>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.put(environment.api + '/back/thread/unsubscribe/' + thread_id,
    {}, { headers });
    return observable.pipe(
      map((raw: any) => {
        this.dService.showNotification({
          title: 'Ticket unsubsribed',
          message: 'Das Ticket wurde erfolgreich unsubscribed.',
          success: true
        });
        return true;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          console.log(error);
        }
        return [];
      })
    )
  }

  getTicket(ticketId: number): Observable<any>{
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.get(environment.api + '/back/thread/'+ticketId, { headers });
    return observable.pipe(
      map((raw: any) => {
        return raw.thread;
      }),
      catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  changeNotificationTickets(data: any) {
    this.notificationTicketChange.next(data);
  }

  getDefaultFilter() {
    this.defaultFilter.only_subscribed = !(localStorage.getItem('user_id') == "45" || localStorage.getItem('user_id') == "47");
    return this.defaultFilter;
  }
}
