import { ContactPerson } from "./ContactPerson";
import { Address } from "./Address";
import { Product } from "../product/Product";
import { ProductWaymark } from "../product/ProductWaymark";
import { User } from "../user/User";
import { Project } from "../project/Project";
import { Thread } from "../thread/Thread";
import { BillngAddress } from "./BillngAddress";
import { Section } from "../project/Section";

export enum CUSTOMERTYP {
  CUSTOMER = 1,
  INTERESTED = 2
}

export class Customer {
    public projects: Project[] = [];
    public threads: Thread[] = [];
    public status = 2;
    public seller: User | undefined;
    public isSelected = false;
    public isSearchedContact = false;
    constructor(
        public customer_id: number,
        public created_from: number,
        public has_dataprivacy: boolean,
        public seller_id: number,
        public company_name: string,
        public billing_address: BillngAddress,
        public contact_persons: ContactPerson[],
        public customer_typ: number,
        public has_rating: boolean,
        public website_url: string,
        public last_appointments: number[],
        public sections: Section[],
        public is_waymark: boolean = true,
        public is_autobase: boolean = false,
        public is_zweispurig: boolean = false,
        public is_autoscout: boolean = false,
        public search_terms: string[] = [],
        public creation_date?: number,
    ){
      this.contact_persons.sort((a,b) => a.contact_id - b.contact_id)
    }

    getLastAppointmentStatus(): string {
      let date = new Date(this.last_appointments[this.last_appointments.length-1]);
      let diff = (new Date().getTime() - date.getTime()) / 1000;
      diff = diff / ((60*60*24*10*3));
      var diffMonths = Math.abs(Math.round(diff));
      if(diffMonths >= 8){
        return 'code_red';
      }
      if(diffMonths >= 6){
        return 'code_orange';
      }
      return 'code_green';
    }

    getCustomerReceiver(contact: ContactPerson) {
      return contact.bill_receiver;
    }

    getJSON(): any {
      let contacts: any[] = [];
      this.contact_persons.forEach(contact => {
        contacts.push({
          "contact_person_id": contact.contact_id,
          "is_polite": contact.isPolite,
          "is_male" : contact.isMale,
          "position" : contact.position,
          "first_name": contact.firstName,
          "last_name": contact.lastName,
          "phone": contact.phone,
          "email": contact.email,
          "billing_email": contact.email,
          "title": {
              "title_id": contact.title.id
          }
        })
      });
      let sections = [];
      this.sections.forEach(section => {
        let sectionRows = [];
        section.section_rows.forEach(row => {
          sectionRows.push({
            "section_row_id" : row.section_row_id,
            "title" : row.title,
            "type": row.type,
            "data_value": row.data_value?.length > 0 ? row.data_value : null
          })
        });
        sections.push({
          "section_id": section.section_id,
          "name": section.section_name,
          "section_rows": sectionRows,
        })
      });
      let body = {
        "customer" : {
          "customer_id" : this.customer_id,
          "seller_id" : this.seller_id,
          "created_from" : this.created_from,
          "has_dataprivacy": this.has_dataprivacy,
          "company_name" : this.company_name,
          "billing_address" :{
              "company_name" : this.billing_address.company_name,
              "atu_number": this.billing_address.atu_number,
              "contact_person" : {
                "contact_person_id": this.billing_address.contact_person.contact_id,
                "is_polite": this.billing_address.contact_person.isPolite,
                "is_male" : this.billing_address.contact_person.isMale,
                "position" : this.billing_address.contact_person.position,
                "first_name": this.billing_address.contact_person.firstName,
                "last_name": this.billing_address.contact_person.lastName,
                "phone": this.billing_address.contact_person.phone,
                "email": this.billing_address.contact_person.email,
                "billing_email": this.billing_address.contact_person.email,
                "title": {
                    "title_id": this.billing_address.contact_person.title.id,
                }
              },
              "address" : {
                "street" : this.billing_address.address.street,
                "postal_code" : this.billing_address.address.postalCode,
                "city" : this.billing_address.address.city
              }
          },
          "contact_persons" : contacts,
          "typ": this.customer_typ,
          "has_rating": this.has_rating,
          "website_url": this.website_url,
          "last_appointment": this.last_appointments,
          "sections": sections,
          "creation_date": this.creation_date ? this.creation_date : new Date().getTime(),
          "is_waymark": this.is_waymark ?? 1,
          "is_autobase": this.is_autobase ?? 0,
          "is_zweispurig": this.is_zweispurig ?? 0,
          "is_autoscout": this.is_autoscout ?? 0
        }
      }
      return body;
    }

    openUrl() {
      let url = this.website_url;
      if (!/^https?:\/\//i.test(url)) {
        url = 'https://' + url;
      }
      url = url.replace(/^https?:\/\/www\./i, 'https://');
      window.open(url, '_blank').focus();
    }
}
