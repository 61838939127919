
<div *ngIf="showView == view.EDITING" class="view_body_create">
    <div id="create_header">
      <h1>Telefonliste</h1>
    </div>
      <div class="buttons-header">
        <div id="phone_create_button_wrapper">
          <button class="btn_tab" [ngClass]="planningActive ? 'btn_tab_blue' : 'btn_tab_blue_light'" (click)="openPlanningView()">Planung</button>
          <button class="btn_tab" [ngClass]="!planningActive ? 'btn_tab_blue' : 'btn_tab_blue_light'" >Bearbeitung</button>
        </div>
        <div id="phone_create_button_wrapper">
          <button class="btn_tab btn_tab_blue_light" (click)="selectionCreate()">Anruf erstellen</button>
        </div>
    </div>
    <div id="menu_container">
      <div id="search-header">
        <div class="search-input-wrapper">
          <input #search autocomplete="off" id="input-search-calls" type="text" placeholder="Anrufe durchsuchen" (input)="searchCalls(search.value)" >
          <img src="./assets/delete-gray.svg" (click)="clearSearchBar()" >
        </div>
        <!-- <div class="filter-wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" width="11.249" height="12.275" viewBox="0 0 11.249 12.275">
            <path id="filter_1_" data-name="filter (1)" d="M7.65,12.275a.511.511,0,0,1-.307-.1L5.3,10.639a.511.511,0,0,1-.2-.409V7.355L1.5,3.318A1.995,1.995,0,0,1,3,0h7.263a1.995,1.995,0,0,1,1.49,3.318L8.162,7.355v4.409A.511.511,0,0,1,7.65,12.275Z" transform="translate(-1.002)" fill="#3b88cc"/>
          </svg>
        </div> -->
      </div>
      <div id="sidebar-details-body" *ngIf="callsFiltered">
        <app-phonelist-sidebar
          #appPhonelistSidebar
          [calls]="callsFiltered"
        ></app-phonelist-sidebar>
        <app-phonelist-details
          [calls]="callsFiltered"
          [users]="users"
          [customers]="customers"
          (removeCallEmitter)="removePhoneCall($event)"
        ></app-phonelist-details>
      </div>
    </div>
</div>

<app-phonelist-create *ngIf="showView == view.CREATE"
  [call]="this.selectedCall"
  [timeStampSets]="timeStampSets"
  (createSuccessEmitter)="createSuccess($event)"
  (updateSuccessEmitter)="updateSuccess($event)"
  (selectionCancelEmitter)="selectionCancelCreate()"
></app-phonelist-create>

<app-phonelist-planning *ngIf="showView == view.PLANNING && callsPlanning"
  #appPhonelistPlanning
  [calls]="callsPlanning"
  [timeStampSets]="timeStampSets"
  (switchViewListEmitter)="switchViewList()"
  (switchViewCreateEmitter)="switchViewCreate()"
  (editEmitter)="changeViewToEdit($event)"
  (deleteCallEmitter)="removePhoneCall($event)"
  (updateCallEmitter)="updateSuccess($event)"
></app-phonelist-planning>
