import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CloneObject } from 'src/app/helper/CloneObject';

@Component({
  selector: 'app-dialog-filter-tickets',
  templateUrl: './dialog-filter-tickets.component.html',
  styleUrls: ['./dialog-filter-tickets.component.scss']
})
export class DialogFilterTicketsComponent implements OnInit {
  @Input() defaultFilter: any;
  @Input() filter: any;
  @Output() closeTicketFilterEmitter = new EventEmitter<any>();
  @Output() applyFilterEmitter = new EventEmitter<any>();

  public selectFilterTypes = [
    { label: 'Alle', value: 0 },
    { label: 'Todo', value: 1 },
    { label: 'Feature', value: 2 },
    { label: 'Feature Intern', value: 6},
    { label: 'Bug', value: 3 },
    { label: 'Frage', value: 4 },
    { label: 'Doku', value: 5 },
  ];
  public selectFilterStatus = [
    { label: 'Alle', value: 0 },
    { label: 'Offen', value: 1 },
    { label: 'Gelöst', value: 2 }
  ];
  public selectedFilterType: any;
  public selectedFilterStatus: any;
  public isSubscribed = false;
  public submitted = false;
  public formGroup: FormGroup;

  constructor(public formbuilder: FormBuilder) { }

  ngOnInit(): void {
    this.formGroup = this.createFilterForm(this.filter);
    this.selectedFilterType = this.formGroup.get('ticket_typ')?.value;
    this.selectedFilterStatus = this.formGroup.get('status')?.value;
    this.isSubscribed = this.formGroup.get('only_subscribed')?.value;
  }

  createFilterForm(filterValue?: any): FormGroup {
    let form: FormGroup;
    form = this.formbuilder.group({
      ticket_typ: [filterValue.ticket_typ, [Validators.required]],
      status: [filterValue.status, [Validators.required]],
      only_subscribed: [filterValue.only_subscribed || null]
    })
    return form;
  }

  submitFilterForm() {
    this.submitted = true;
    if(this.formGroup.invalid) return;
    this.filter.ticket_typ = this.formGroup.controls['ticket_typ'].value;
    this.filter.status = this.formGroup.controls['status'].value;
    this.filter.only_subscribed = this.formGroup.controls['only_subscribed'].value;
    this.applyFilterEmitter.emit(this.filter);
    this.closeTicketFilterEmitter.emit(this.filter);
  }

  resetFilterForm() {
    this.filter = CloneObject.deepCopy(this.defaultFilter);
    this.applyFilterEmitter.emit(this.filter);
    this.closeTicketFilterEmitter.emit(this.filter);
  }

  close() {
    this.closeTicketFilterEmitter.emit(this.filter);
  }

  public toggleTypeFilter(type: { label: string, value: number }): void {
    if (type.value === 0) {
      if (this.selectedFilterType.includes(0)) {
        this.selectedFilterType = [];
      } else {
        this.selectedFilterType = this.selectFilterTypes.map(t => t.value);
      }
    } else {
      const index = this.selectedFilterType.indexOf(type.value);
      if (index > -1) {
        this.selectedFilterType.splice(index, 1);
        const indexAll = this.selectedFilterType.indexOf(0);
        if (indexAll > -1) {
          this.selectedFilterType.splice(indexAll, 1);
        }
      } else {
        this.selectedFilterType.push(type.value);
        const allTypesSelected = this.selectFilterTypes
          .filter(t => t.value !== 0)
          .every(t => this.selectedFilterType.includes(t.value));
          if (allTypesSelected && !this.selectedFilterType.includes(0)) {
          this.selectedFilterType.push(0);
        }
      }
    }
    this.formGroup.controls['ticket_typ'].setValue(this.selectedFilterType);
  }

  public toggleStatusFilter(status: { label: string, value: number }): void {
    this.selectedFilterStatus = status.value;
    this.formGroup.controls['status'].setValue(this.selectedFilterStatus);
  }

  public toggleSubscribedFilter() {
    this.isSubscribed = !this.isSubscribed;
    this.formGroup.controls['only_subscribed'].setValue(this.isSubscribed);
  }
}
