import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss']
})
export class ActionMenuComponent implements OnInit {
  @Output() editEmitter = new EventEmitter<any>();
  @Output() createProjectEmitter = new EventEmitter<any>();
  @Output() selectionProjectEmitter = new EventEmitter<any>();
  @Output() selectionCreateTicket = new EventEmitter<any>();
  @Output() deleteCustomerEmtter = new EventEmitter<any>();
  public accessDelete = false;

  constructor() { }

  ngOnInit(): void {
    const userId = Number(localStorage.getItem('user_id'));
    if(userId == 45 || userId == 46 || userId == 47){
      this.accessDelete = true;
    }
  }
}
