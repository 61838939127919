<div id="app_container">
  <app-loading></app-loading>
  <app-menu-header *ngIf="showMenu(outlet)"></app-menu-header>
  <router-outlet #outlet="outlet"></router-outlet>
  <app-dialog-notification></app-dialog-notification>
  <app-dialog-query></app-dialog-query>
  <app-dialog-information></app-dialog-information>
</div>
<div *ngIf="isTest" id="test_identifier">
  <h2>Test</h2>
  <label>{{version}}</label>
</div>
<div *ngIf="!isTest" id="prod_identifier">
  <label>{{version}}</label>
</div>
