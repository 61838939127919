import { AfterViewInit, Component, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/service/dialog/dialog.service';

@Component({
  selector: 'app-dialog-notification',
  templateUrl: './dialog-notification.component.html',
  styleUrls: ['./dialog-notification.component.scss']
})
export class DialogNotificationComponent implements OnInit, AfterViewInit {
  public show = false;
  public isSuccess = true;
  public message!: string;
  public title!: string;
  public subscription!: Subscription;
  public move = 0;

  constructor(
    private dService : DialogService,
    private router: Router
  ) {
    this.subscription = this.dService.notification$.subscribe((data: any) => {
      this.title = data.title;
      this.message = data.message;
      this.isSuccess = data.success;
      this.show = true;
      // setTimeout(() => {
      //   this.get();
      // }, 200);
      setTimeout(() => {
        this.show = false;
        this.setDefault();
      }, 2500);
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get() {
    let dialog = document.getElementById('notification_container');
    if(dialog){
      dialog.addEventListener('click',(e) => {
        this.move++;
        if(this.move == 1) dialog.style.top = '90vh'
        else if(this.move == 2) dialog.style.left = '22.5vw'
        else if(this.move == 3) dialog.style.top = '0.5vh'
        else if(this.move == 4) {
          dialog.style.left = 'calc(70vw - 210px)'
          setTimeout(() => {
            dialog.style.top = 'calc(50vh - 40px)'
            setTimeout(() => {
              let html = document.getElementById('html');
              html.style.backgroundColor = '#000000';
              let body = document.getElementById('body');
              body.style.opacity = '0';
              setTimeout(() => {
                this.dService.setSuccess();
                this.router.navigate(['success'])
              }, 200);
            }, 200);
          }, 500);
        }
      })
    }
  }

  setDefault() {
    let dialog = document.getElementById('notification_container');
    if(dialog){
      dialog.addEventListener('click',(e) => {
        this.move = 0;
        dialog.style.top = '0.5vh'
        dialog.style.left = '99.5vh'
        let html = document.getElementById('html');
        html.style.backgroundColor = 'var(--color-interface-4)';
        let body = document.getElementById('body');
        body.style.opacity = '0';
      })
    }
  }
}
