import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Project } from 'src/app/buisness-object/project/Project';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { User } from 'src/app/buisness-object/user/User';
import { CloneObject } from 'src/app/helper/CloneObject';
import { BaseComponent } from '../../BaseComponent';
import { Message } from 'src/app/buisness-object/thread/Message';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { BehaviorSubject, catchError, filter, firstValueFrom, map, Observable, of, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TicketForm } from 'src/app/buisness-object/thread/form/TicketForm';
import { ErrorHelper } from 'src/app/utils/ErrorHelper';
import { MessageService } from 'src/app/service/message/message.service';
import { CallForm } from 'src/app/buisness-object/call/form/CallForm';
import { CustomerForm } from 'src/app/buisness-object/customer/form/CustomerForm';
import { PhoneCallService } from 'src/app/service/phone-call/phone-call.service';
import { Call } from 'src/app/buisness-object/call/Call';
import { ProjectService } from 'src/app/service/project/project.service';

@Component({
  selector: 'app-phonelist-tickets',
  templateUrl: './phonelist-tickets.component.html',
  styleUrls: ['./phonelist-tickets.component.scss']
})
export class PhonelistTicketsComponent extends BaseComponent implements OnInit {

  @Input() users: User[] = [];
  @Input() customers: Customer[] = [];
  @Input() project: Project;
  @Input() call: Call;
  @Output() ticketCreatedEmitter = new EventEmitter<any>();
  @Output() putDoneEmitter = new EventEmitter<any>();

  // @Output() showCreateEmitter = new EventEmitter<boolean>();
  @Output() resolveEmitter = new EventEmitter<Thread>();
  // @Output() applyFilterEmitter = new EventEmitter<any>();
  // @Output() updateProjectTicketsEmitter = new EventEmitter<Thread[]>();

  public tickets: Thread[] = [];
  public ticketsFiltered: Thread[] = [];
  public openNotificationTickets: Thread[] = [];
  public selectedTicket$ = new BehaviorSubject<any>(null);
  public selectedTicketMessages: Message[] = [];
  public openTicketsCount;
  public currentUser;
  public infoActive = false;
  public isTicketlistActive = true;
  public defaultFilter: any;
  public filterValue: any;
  searchTimeout: any;
  dialogQuerySubsription: Subscription;
  public ticketForm: FormGroup;
  public submitted = false;
  public searchValueTemp;
  public showFilterTickets = false;
  public selectTypes = [
    { label: 'Todo', value: 1 },
    { label: 'Feature', value: 2 },
    { label: 'Feature Intern', value: 6},
    { label: 'Bug', value: 3 },
    { label: 'Frage', value: 4 },
    { label: 'Doku', value: 5 },
  ];
  public selectedType: number = this.selectTypes[0].value;
  public activeFilterCount: number;
  public showMoveTicket = false;
  public showEditTicket = false;
  public showImageOpen = false;
  public selectedImageUrl: string = '';
  public droppedImageBase64: string | null = null;
  public ongoingMessagesDataRequest = false;
  public showDropdown = false;
  public showDropdown2 = false;
  public customerValues;
  public customersFiltered: Customer[] = [];
  public projects = [];

  constructor(
    private ticketService: ThreadService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private fromBuilder: FormBuilder,
    private projectService: ProjectService
    ) {
    super();
  }

  ngOnInit(): void {
    this.setUp();
  }

  callReload() {
    this.setUp();
  }

  async setUp() {
    this.ticketForm = TicketForm.getTicketForm(this.fromBuilder);
    this.selectionCustomer(this.customers.find((c) => c.customer_id == this.call.customer_id));
    this.ticketForm.get('message').setValue(this.call.driver_note);
    this.customersFiltered = this.customers;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let listMessages = document.getElementById('ticket-details-body');
      if(listMessages){
        listMessages.scrollTop = listMessages.scrollHeight;
      }
      if(this.ticketForm.get('customer').value){
        this.updateInputCustomer(this.ticketForm.get('customer').value?.company_name);
      }
    }, 200);
  }

  ngOnChanges(changes: SimpleChanges): void {
      if(this.searchValueTemp){
        setTimeout(() => {
          let inputSearch = document.getElementById('input-search-tickets') as HTMLInputElement;
          if(inputSearch) inputSearch.value = this.searchValueTemp;
        }, 50);
      }
  }

  ngOnDestroy(): void {
    if(this.searchTimeout) clearTimeout(this.searchTimeout);
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  getMessageIcon(message: Message): string {
    switch (message.attachment_type) {
      case 'image': return './assets/document-transaction.svg';  break;
      default: return './assets/tickedaddedcomment.svg'; break;
    }
  }

  async getMessageImages() {
    this.ongoingMessagesDataRequest = true;
    let ticket = this.selectedTicket$.getValue();
    let promises: Promise<any>[] = [];
    if(ticket.messages) {
      for(let msg of ticket.messages){
        msg.icon = this.getMessageIcon(msg);
        if(msg.attachment_type == "image" && msg.attachment == undefined) {
          promises.push(firstValueFrom(this.getMessageImage(ticket.threadId, msg)));
        }
      }
    }
    await Promise.all(promises).then((results) => {
      results.forEach((updatedMessage) => {
        let originalMessage = ticket.messages.find((msg) => msg.messageId === updatedMessage.messageId);
        if(originalMessage) {
          originalMessage.attachment = updatedMessage.attachment;
        }
      });
      this.selectedTicketMessages = ticket.messages;
      this.selectedTicket$.next(ticket);
      this.ongoingMessagesDataRequest = false;
    });
  }

  getMessageImage(ticketId: number, msg: Message): Observable<Message> {
    return this.messageService.getMessage(ticketId, msg.messageId).pipe(
      map(result => {
        return result;
      }),
      catchError(() => of(null))
    )
  }

  selectionCustomer(customer: Customer) {
    if(!customer){
      return;
    }
    this.ticketForm.get('customer').setValue(customer);
    this.ticketForm.get('customer_id').setValue(customer.customer_id);
    this.ticketForm.get('project_id').setValue(null);
    this.projectService.getProjects(customer.customer_id).subscribe((result) => {
      if(result){
        this.projects = result;
        this.updateInputCustomer(customer.company_name);
      }
    })
  }

  selectionProject(project: Project) {
    this.ticketForm.controls['project'].setValue(project);
    this.ticketForm.controls['project_id'].setValue(project.project_id);
    this.updateInputProject(project.awork_project.name);
  }

  updateInputCustomer(value: string){
    const inputCustomer = document.getElementById('input-customer') as HTMLInputElement;
    if(inputCustomer){
      inputCustomer.value = value;
    }
  }

  updateInputProject(value: string) {
    const inputContact = document.getElementById('input-project') as HTMLInputElement;
    if(inputContact){
      inputContact.value = value;
    }
  }


  // setValueFromDropdown(value: string, id: number) {
  //   if(value === 'company_name') {
  //     let index = this.customers.findIndex(obj => obj.customer_id == id);
  //     if(index > -1){
  //       this.form.controls['customer'].setValue(this.customers[index]);
  //       this.form.controls['company_name'].setValue(this.customers[index].company_name);
  //       this.form.controls['customer_id'].setValue(this.customers[index].customer_id);
  //       this.form.get('customer_form').setValue(customerForm);
  //     }
  //   } else if(value == 'project') {

  //   }
  // }

  selectionTicket(ticket: Thread) {
    if(ticket.notifyOpen){
      ticket.notifyOpen = false;
      this.ticketService.updateThread(ticket).subscribe((t) => {
        if(t){
          let index1 = this.tickets.findIndex(t => t.threadId == ticket.threadId);
          if(index1 > -1) this.tickets[index1].notifyOpen = false;
          let index2 = this.ticketsFiltered.findIndex(t => t.threadId == ticket.threadId);
          if(index2 > -1) this.ticketsFiltered[index2].notifyOpen = false;
        }
      })
    }
    this.ticketForm = TicketForm.getTicketForm(this.fromBuilder);
    const content = document.getElementById('text-input-1');
    if(content) content.innerText = '';
    this.selectedTicket$.next(ticket);
    let makeRelative = false;
    if(this.selectedTicket$.getValue().getThreadTitle().length > 500){
      makeRelative = true;
    }
    setTimeout(() => {
      const ticketHeader = document.getElementById('ticket-details-header');
      if(ticketHeader){
        if(ticketHeader.offsetHeight > 280) ticketHeader.setAttribute('style', this.getStyleNotSticky());
        else ticketHeader.setAttribute('style', this.getStyleSticky());
      }
    }, 200);
  }

  getStyleSticky(): string {
    return 'position: sticky; ' +
      'top: 0; ' +
      'left: 0; ' +
      'background-color: #ffffff; ' +
      'padding: 10px 12px; ' +
      'z-index: 100;';
  }

  getStyleNotSticky(): string {
    return 'position: relative; ' +
      'top: 0; ' +
      'left: 0; ' +
      'background-color: #ffffff; ' +
      'padding: 10px 12px; ' +
      'z-index: 100;';
  }

  toggleTicketlist(): void {
    if (this.isPhone) {
      this.isTicketlistActive = !this.isTicketlistActive;
      document.getElementById("ticket-list").style.display = this.isTicketlistActive ? 'block' : 'none';
      document.getElementById("ticket-details").style.display = this.isTicketlistActive ? 'none' : 'block';
    }
  }

  resolve(ticket: Thread) {
    let temp = CloneObject.deepCopy(ticket);
    temp.status = 2;
    temp.resolved = new Date().getTime();
    temp.resolved_by_user_id = this.currentUser.userId;
    temp.resolved_by_user = this.currentUser;
    this.ticketService.updateThread(temp).subscribe((thread) => {
      if(thread){
        this.openTicketsCount--;
        this.selectionTicket(temp);
        this.updateTicketLocal(temp);
        this.resolveEmitter.emit(temp)
        this.dialogService.showNotification({
          title: 'Ticket aufgelöst',
          message: this.dialogService.getTitle(),
          success: true
        });
      }
    });
  }

  updateTicketLocal(ticket: Thread) {
    let index1 = this.tickets.findIndex(t => t.threadId == ticket.threadId);
    if(index1 > -1) this.tickets[index1] = ticket
    let index2 = this.ticketsFiltered.findIndex(t => t.threadId == ticket.threadId);
    if(index2 > -1) this.ticketsFiltered[index2] = ticket;
  }

  public onSelectType(type: { label: string, value: number }): void {
    this.selectedType = type.value;
    this.ticketForm.get('thread_typ')?.setValue(type.value);
  }

  handleImageDropped(base64Image: string) {
    this.droppedImageBase64 = base64Image;
  }

  createTicket() {
    const attachmentType = this.droppedImageBase64 ? 'image' : 'text';
    const attachment = this.droppedImageBase64 ? this.droppedImageBase64 : null;
    let messageContent = this.ticketForm.controls['message'].value;
    if(attachment) {
      messageContent = '';
    }

    this.submitted = true
    if(this.ticketForm.get('subject').invalid || this.ticketForm.get('thread_typ').invalid){
      ErrorHelper.showFormError(this.ticketForm);
      return;
    }
    let thread = new Thread(
      0,
      this.ticketForm.controls['customer_id'].value,
      Number(localStorage.getItem('user_id')),
      this.ticketForm.controls['thread_typ'].value,
      1,
      false,
      false, //notification
      Date.now(),
      0,
      0,
    );

    thread.subject = this.ticketForm.get('subject').value;
    thread.project_id = this.ticketForm.get('project_id').value;
    thread.messages.push(new Message(
      0,
      0,
      thread.userId,
      messageContent,
      Date.now(),
      attachmentType,
      attachment
    ));
    this.ticketService.createThread(thread).subscribe((newTicket) => {
      if(newTicket){
        this.dialogService.showNotification({
          title: 'Erfolgreich',
          message: 'Ticket wurde erstellt.',
          success: true
        });
        this.submitted = false;
        this.ticketCreatedEmitter.emit(this.call.call_id);
      }
    });
  }

  clearCustomer() {
    this.ticketForm.get('customer_id').setValue(null);
    this.ticketForm.get('customer').setValue(null);
    this.ticketForm.get('project_id').setValue(null);
    this.ticketForm.get('project').setValue(null);
    this.projects = [];
  }

  searchCustomers(searchValue: string){
    this.clearCustomer();
    this.customersFiltered = [];
      if(this.customers && searchValue && searchValue.length > 1) {
        this.customersFiltered = this.customers.filter((customer) => {
          return customer.company_name?.toLowerCase().startsWith(searchValue.toLowerCase()) ||
                 customer.company_name?.toLowerCase().includes(searchValue.toLowerCase())
        })
      } else {
        this.customersFiltered = this.customers;
      }
  }

  searchIncludesContact(customer: Customer, value: string): boolean {
    for(let c of customer.contact_persons){
      return c.firstName.toLowerCase().includes(value) ||
             c.lastName.toLowerCase().includes(value) ||
            (c.firstName + ' ' + c.lastName).toLowerCase().includes(value);
    }
    return false;
  }
}
