import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { User } from 'src/app/buisness-object/user/User';
import { MobileService } from 'src/app/service/mobile/mobile.service';

@Component({
  selector: 'app-customer-sidebar',
  templateUrl: './customer-sidebar.component.html',
  styleUrls: ['./customer-sidebar.component.scss']
})
export class CustomerSidebarComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input() customers!: Customer[];
  @Input() users!: User[];
  @Input() filter!: any;
  @Input() paraCustomerId!: number;
  @Input() minimizeSidebar!: boolean;
  @Output() selectionCustomerEmitter = new EventEmitter<Customer>();
  @Output() searchEmitter = new EventEmitter<string>();
  @Output() filterEmitter = new EventEmitter<undefined>();
  @Output() minimizeEmitter = new EventEmitter<undefined>();
  @Output() applyFilterEmitter = new EventEmitter<undefined>();
  @Output() removeFilterStatusEmitter = new EventEmitter<undefined>();
  @Output() removeFilterSellerEmitter = new EventEmitter<undefined>();
  @Output() removeFilterClassificationEmitter = new EventEmitter<undefined>();

  public showDropdowOne = false;
  public showDropdowTwo = false;
  @ViewChild('dropdown_1') dropdown_1! : ElementRef;
  @ViewChild('dropdown_2') dropdown_2! : ElementRef;
  public listener!: () => void;
  public inputTimeout: any;
  public showFilterUser = false;
  public showFilterStatus = false;
  public showFilterClassification = false;

  constructor(
    private renderer: Renderer2,
    public mService: MobileService
  ) { }

  closeMobileMenu() {
    this.mService.closeMobileMenu(undefined);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['paraCustomerId']){
      this.scrollToCustomer(this.paraCustomerId);
    }
  }

  ngAfterViewInit(): void {
    this.listener = this.renderer.listen('window','click',(e:Event) => {
      if(this.showDropdowOne && !this.dropdown_1.nativeElement.contains(e.target)) this.showDropdowOne = false;
      if(this.showDropdowTwo && !this.dropdown_2.nativeElement.contains(e.target)) this.showDropdowTwo = false;
    });
    if(this.paraCustomerId) this.scrollToCustomer(this.paraCustomerId)
  }

  ngOnDestroy(): void {
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    if(this.listener) this.listener();
  }

  selectionCustomer(customer: Customer) {
    this.selectionCustomerEmitter.emit(customer);
  }

  searchAction(value: string) {
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.searchEmitter.emit(value);
    }, 300);
  }

  filterPostalCode(value: string) {
    if(this.inputTimeout) clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      this.filter.filter_postal_code = value;
      this.filterEmitter.emit();
    }, 300);
  }

  getFilterName(): string {
    if(this.filter.filter_typ == 0) return 'Alle anzeigen';
    else if(this.filter.filter_typ == 1) return 'Kunden';
    else if(this.filter.filter_typ == 2) return 'Interessente';
    else if(this.filter.filter_typ == 3) return 'Archiv';
    else return '---'
  }

  scrollToCustomer(customer: number) {
    const scrollView = document.getElementById('sidebar_customer_list');
    const element = document.getElementById('customer_'+customer);
    if(element && scrollView){
      scrollView.scrollTop = element.offsetTop - 270;
    }
  }

  getSortInfo(): string {
    switch (this.filter.sort_after) {
      case 1: return '(nach Alphabet)'; break;
      case 2: return '(nach Letzter Termin)'; break;
      case 3: return '(nach PLZ)'; break;
      default: return ''; break;
    }
  }
}
