import { User } from 'src/app/buisness-object/user/User';
import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, Renderer2, Output, EventEmitter,HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';
import { Title } from 'src/app/buisness-object/customer/Title';
import { ProductWaymark } from 'src/app/buisness-object/product/ProductWaymark';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { Message } from 'src/app/buisness-object/thread/Message';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { InputFiller } from 'src/app/helper/InputFiller';
import { CustomerForm } from 'src/app/buisness-object/customer/form/CustomerForm';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { ContactPerson } from 'src/app/buisness-object/customer/ContactPerson';
import { Address } from 'src/app/buisness-object/customer/Address';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { BillngAddress } from 'src/app/buisness-object/customer/BillngAddress';
import { Section, SectionRow } from 'src/app/buisness-object/project/Section';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-customer-create',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.scss']
})
export class CustomerCreateComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() customer!: Customer | undefined;
  @Input() isEdit!: boolean;
  @Input() users!: User[];
  @Output() createCustomerEmitter = new EventEmitter<undefined>();
  @Output() cancelEmitter = new EventEmitter<undefined>();
  @Output() updateCustomerEmitter = new EventEmitter<undefined>();
  @Output() addContactFormEmitter = new EventEmitter<undefined>();
  @Output() removeContactEmitter = new EventEmitter<number>();
  @Output() createSuccessEmitter = new EventEmitter<Customer>();
  @Output() updateSuccessEmitter = new EventEmitter<Customer>();
  public form!: FormGroup;
  public submitted = false;
  public changeDetected = false;
  public products: ProductWaymark[] = [];
  public titles: Title[] = [];
  public maxDate = formatDate(new Date(),'yyyy-MM-dd','de');
  public minDate = '2017-01-05';
  public showDropdowOne = false;
  public showDropdowTwo = false;
  public showDropdowThree = false;
  public showDropdowFour = false;
  public showDropdowFive = false;
  public showDropdowSix = false;
  public showDropdowSeven = false;
  public dialogQuerySubsription!: Subscription;
  public formSubscription!: Subscription;
  @ViewChild('inputStatus') inputStatus!: ElementRef;
  @ViewChild('inputSeller') inputSeller!: ElementRef;
  toggles = [
    { label: 'Waymark', property: 'is_waymark', isSubscribed: false },
    { label: 'Autobase', property: 'is_autobase', isSubscribed: false },
    { label: 'Zweispurig', property: 'is_zweispurig', isSubscribed: false },
    { label: 'Autoscout', property: 'is_autoscout', isSubscribed: false },
  ];

  constructor(
    private cService: CustomerService,
    private tService: ThreadService,
    private dService: DialogService,
    private formbuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if(this.customer && this.customer.last_appointments?.length > 0){
      this.minDate = formatDate(new Date(this.customer.last_appointments[this.customer.last_appointments.length-1]),'yyyy-MM-dd','de');
    }
    this.getDateServerside();
    this.setDialogSubscription();
    this.form = CustomerForm.createCustomerForm(this.formbuilder,this.customer);
    this.syncToggleStates();
  }

  ngAfterViewInit(): void {
    if(this.customer && this.form){
      InputFiller.setTyp(this.inputStatus,this.form.controls['typ'].value);
      InputFiller.setUserName(this.inputSeller,this.form.controls['seller'].value);
    }
  }

  ngOnDestroy(): void {
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.formSubscription) this.formSubscription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_customer_create') this.cancelCreate();
      }
    });
  }

  getDateServerside() {
    forkJoin({
      one: this.cService.getProducts(),
      two: this.cService.getTitles(),
      sections: this.cService.getSections(),
    }).subscribe((result) => {
      if(result){
        this.products = result.one;
        this.titles = result.two;
        this.form.controls['sections'].setValue(CustomerForm.createSections(this.formbuilder,result.sections,this.customer));
        if(!this.customer) {
          this.form.controls['contacts'].value[0].controls['bill_receiver'].setValue(true);
        } else {
          let index = this.form.controls['contacts'].value.findIndex(f => f.controls['contact_id'].value == this.customer.billing_address.contact_person.contact_id);
          if(index > -1) this.form.controls['contacts'].value[index].controls['bill_receiver'].setValue(true);
        }
        this.formSubscription = this.form.valueChanges.subscribe((value) => {
          this.changeDetected  = true;
          this.formSubscription.unsubscribe();
        })
      }
    })
  }

  removeContactForm(index: number) {
    if(this.form.controls['contacts'].value[index].controls['bill_receiver'].value == true){
      this.form.controls['contacts'].value[index-1].controls['bill_receiver'].setValue(true);
    }
    this.form.controls['contacts'].value.splice(index,1);
  }

  selectionCreate() {
    this.submitted = true;
    for(let form of this.form.controls['contacts'].value){
      if(form.invalid) return;
    }
    let thread = new Thread(0,0,0,3,0,false,false,0,0,0);
    thread.messages.push(new Message(0, thread.threadId,Number(localStorage.getItem('user_id')),'',Date.now(),"text"));
    this.createCustomer();
  }

  createCustomer() {
    let customer = this.createCustomerObject();
    this.cService.createCustomer(customer).subscribe((result) => {
      if(result){
        let thread = this.form.controls['thread'].value;
        if(thread && thread.messages.length > 0){
          thread.customerId = result.customer_id;
          thread.createdDate = new Date();
          this.tService.createThread(thread).subscribe((result2) => {
            if(result2) this.createSuccessEmitter.emit(result);
            else {
              this.dService.showNotification({
                title: 'Fehlgeschlagen',
                message: 'Beim Erstellen des neuen Tickets ist ein Fehler aufgetreten.',
                success: false
              });
            }
          })
        } else {
          this.createSuccessEmitter.emit(result);
        }
      }
    })
  }

  createCustomerObject(): Customer {
    let contacts: ContactPerson[] = [];
    for(let contactForm of this.form.controls['contacts'].value){
      contacts.push(
        new ContactPerson(
          contactForm.controls['contact_id'].value,
          contactForm.controls['contact_polite'].value,
          contactForm.controls['contact_salutation'].value,
          contactForm.controls['contact_first_name'].value,
          contactForm.controls['contact_last_name'].value,
          contactForm.controls['contact_phone'].value,
          contactForm.controls['contact_email'].value,
          contactForm.controls['contact_title'].value,
          contactForm.controls['position'].value,
          contactForm.controls['bill_receiver'].value,
        )
      );
    }
    const billingContact = contacts.find((c) => c.bill_receiver);
    let billingAddress = new BillngAddress(
      this.form.controls['billing_company_name'].value,
      new Address(
        this.form.controls['street'].value,
        this.form.controls['postal_code'].value,
        this.form.controls['city'].value,
      ),
      this.form.controls['atu'].value,
      '',
      0,
      billingContact ? billingContact : contacts[0]
    )
    let sections = [];
    for(let section of this.form.controls['sections'].value){
      let sectionRows = [];
      for(let row of section.controls['section_rows'].value){
        sectionRows.push(new SectionRow(
          row.controls['section_row_id'].value,
          row.controls['type'].value,
          row.controls['data_value'].value,
          row.controls['title'].value,
        ))
      }
      sections.push(new Section(
        section.controls['section_id'].value,
        section.controls['section_name'].value,
        sectionRows
      ))
    }
    let lastAppointements = [];
    if(this.customer) {
      lastAppointements = this.customer.last_appointments;
    }
    if(this.form.controls['last_appointment'].value != null) {
      lastAppointements.push(CustomerForm.getTimestampFromInputDate(this.form.controls['last_appointment'].value));
    }
    lastAppointements = Array.from(new Set(lastAppointements));
    let customer = new Customer(
      this.customer ? this.customer.customer_id : 0,
      this.customer ? this.customer.created_from : Number(localStorage.getItem('user_id')),
      this.form.controls['data_privacy_available'].value,
      this.form.controls['seller'].value.userId,
      this.form.controls['company_name'].value,
      billingAddress,
      contacts,
      this.form.controls['typ'].value,
      this.form.controls['has_rating'].value,
      this.form.controls['website_url'].value,
      lastAppointements,
      sections,
      this.form.controls.is_waymark.value,
      this.form.controls.is_autobase.value,
      this.form.controls.is_zweispurig.value,
      this.form.controls.is_autoscout.value
    );
    return customer;
  }

  selectionUpdate() {
    this.submitted = true;
    if(this.formInvalid()){
      return;
    }
    let customer = this.createCustomerObject();
    this.cService.updateCustomer(customer).subscribe((result) => {
      if(result){
        this.updateSuccessEmitter.emit(result);
      }
    })
  }

  formInvalid(): boolean {
    if(this.form.invalid){
      return true;
    }
    for(let form of this.form.get('contacts').value){
      if(form.invalid){
        return true;
      }
    }
    return false;
  }

  selectionBillReceiver(index: number) {
    for(let i = 0; i < this.form.controls['contacts'].value.length; i++){
      if(i == index) this.form.controls['contacts'].value[i].controls['bill_receiver'].setValue(true);
      else this.form.controls['contacts'].value[i].controls['bill_receiver'].setValue(false);
    }
  }

  selectionProduct(product: ProductWaymark) {
    let index = this.form.controls['products'].value.findIndex((p: any) => p.id == product.id);
    if(index > -1) this.form.controls['products'].value.splice(index,1);
    else this.form.controls['products'].value.push(product);
  }
  isCheckedProduct(product: ProductWaymark): boolean {
    if(this.form.controls['products'].value.findIndex((p: any) => p.id == product.id) == -1) return false;
    else return true;
  }

  selectionCancel() {
    if(this.changeDetected){
      this.dService.openQuery(
        {
          title: 'Verwerfen',
          message: 'Wenn Sie fortsetzen gehen alle Änderungen verloren.',
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Verwerfen',
          typ: 'cancel_customer_create',
          showClose: true,
        }
      );
    } else {
      this.cancelCreate();
    }
  }

  cancelCreate() {
    this.customer = null;
    this.cancelEmitter.emit();
  }

  selectionAddContact() {
    this.form.controls['contacts'].value.push(this.addContactForm())
  }
  addContactForm(contact?: ContactPerson): FormGroup {
    return CustomerForm.addContactForm(this.formbuilder,contact);
  }

  toggleMethod(toggle: any) {
    toggle.isSubscribed = !toggle.isSubscribed;
    this.form.patchValue({ [toggle.property]: toggle.isSubscribed });
  }

  private syncToggleStates() {
    this.toggles.forEach(toggle => {
      toggle.isSubscribed = this.form.value[toggle.property];
    });
  }
}
