<a (click)="onCallSelected()" class="phone-call-item"
  [class.phone-call-item-active]="active">
  <div class="phone-call-item-body">
    <div class="phone-call-item-body-header">
      <div class="phone-call-item-body-header-column-1">
        <h2>#{{call.call_id}} <span>{{call.note}}</span></h2>
        <label>{{call.getName()}}, {{call.due_date | date: ' dd.MMM.YYYY, HH:mm'}}</label>
      </div>
    </div>
    <div class="phone-call-item-body-footer">
      <div class="phone-call-item-body-header-column-2">
        <label
        *ngIf="call.category"
        class="lbl-tag"
        [ngClass]="{
          'kunde': call.category === 'Kunde',
          'interessent': call.category === 'Interessent',
          'partner': call.category === 'Partner',
          'lieferant': call.category === 'Lieferant',
          'privat': call.category === 'Privat',
          'sonstige': call.category === 'Sonstige'
        }"
        >{{ call.category }}</label>
        <label class="lbl-tag tag-red">Offen</label>
      </div>
    </div>
  </div>
  <img class="phone-call-item-arrow" src="./assets/arrowmenucolor.svg"/>
</a>
