import { formatDate } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { forkJoin, from, Subscription } from 'rxjs';
import { Address } from 'src/app/buisness-object/customer/Address';
import { BillngAddress } from 'src/app/buisness-object/customer/BillngAddress';
import { ContactPerson } from 'src/app/buisness-object/customer/ContactPerson';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Title } from 'src/app/buisness-object/customer/Title';
import { AworkProject, Tag } from 'src/app/buisness-object/project/AworkProject';
import { ProjectForm } from 'src/app/buisness-object/project/form/ProjectForm';
import { Project } from 'src/app/buisness-object/project/Project';
import { ProjectTag } from 'src/app/buisness-object/project/ProjectTag';
import { ProjectType } from 'src/app/buisness-object/project/ProjectType';
import { SectionRow, Section } from 'src/app/buisness-object/project/Section';
import { GlobalVariables } from 'src/app/helper/GlobalVariables';
import { getColorClass } from 'src/app/helper/HelperFunctions';
import { InputFiller } from 'src/app/helper/InputFiller';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { ProjectService } from 'src/app/service/project/project.service';
import { ErrorHelper } from 'src/app/utils/ErrorHelper';

@Component({
  selector: 'app-customer-create-project',
  templateUrl: './customer-create-project.component.html',
  styleUrls: ['./customer-create-project.component.scss']
})
export class CustomerCreateProjectComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() isEdit!: boolean;
  @Input() contacts!: ContactPerson[];
  @Input() customer!: Customer;
  @Input() selectedProject!: Project;
  @Output() cancelEmitter = new EventEmitter<undefined>();
  @Output() addContactFormEmitter = new EventEmitter<undefined>();
  @Output() addInfoEmitter = new EventEmitter<undefined>();
  @Output() removeContactEmitter = new EventEmitter<number>();
  @Output() removeInfoEmitter = new EventEmitter<number>();
  @Output() removeDifferentAddressEmitter = new EventEmitter<number>();
  @Output() createProjectSuccesEmitter = new EventEmitter<Project>();
  @Output() updateProjectSuccessEmitter = new EventEmitter<Project>();
  public form!: FormGroup;
  public submitted = false;
  public changeDetected = false;
  public showDialogContacts = false;
  public showDifferentAddress = false;
  public showDropdowOne = false;
  public showDropdowTwo = false;
  public showDropdowThree = false;
  @ViewChild('inputTyp') inputTyp! : ElementRef;
  public projectTyps: ProjectType[] = [];
  public projectTags: ProjectTag[] = [];
  public titles: Title[] = [];
  public formAddressSubscription: Subscription;
  public formSubscription: Subscription;
  public dialogQuerySubsription: Subscription;
  public getColor = getColorClass;
  public description_char_count = 250;
  public minDate = formatDate(new Date(),'yyyy-MM-dd','de');

  constructor(
    private dService: DialogService,
    private pService: ProjectService,
    private formbuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.getDateServerside();
    this.createProjectForm(this.selectedProject);
    this.setDialogSubscription();
  }

  ngAfterViewInit(): void {
    if(this.selectedProject) this.prepareUpateView()
  }

  ngOnDestroy(): void {
    if(this.formAddressSubscription) this.formAddressSubscription.unsubscribe();
    if(this.formSubscription) this.formSubscription.unsubscribe();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'cancel_project_create') this.cancelCreate();
      }
    });
  }

  getDateServerside() {
    forkJoin({
      typs: this.pService.getProjectTyps()
    }).subscribe((result) => {
      if(result){
        this.projectTyps = result.typs;
        if(this.selectedProject){
          for(let typ of this.projectTyps){
            if(this.selectedProject.awork_project.project_typ.typ_id == typ.typ_id){
              this.selectionTyp(typ);
            }
          }
        }
        if(this.selectedProject){
          this.mergeSections();
          this.form.controls['tags'].setValue(this.selectedProject.awork_project.project_typ.tags);
        }
        this.formSubscription = this.form.valueChanges.subscribe((value) => {
          this.changeDetected  = true;
        })
      }
    })
  }

  prepareUpateView() {
    let el = document.getElementById('typ_disabled');
    let el2 = document.getElementById('typ_disabled_2');
    if(el && el2){
      el.style.opacity = '0';
      el2.style.opacity = '0';
      el!.style.display = 'none';
      el2!.style.display = 'none';
    }
    InputFiller.setProjectType(this.inputTyp, this.form.controls['project_typ'].value);
  }

  selectionAddDifferentAddress() {
    this.form.controls['add_different_address'].setValue(true);
    ProjectForm.addValidatorsToAddress(this.form);
  }

  selectionTag(tag: ProjectTag) {
    let i = this.form.controls['tags'].value.findIndex(t => t.name == tag.name);
    if(i > -1) this.form.controls['tags'].value.splice(i,1);
    else this.form.controls['tags'].value.push(tag);
  }
  tagIsSelected(tag: ProjectTag): boolean {
    if(this.form.controls['tags'].value.findIndex(t => t.name == tag.name) > -1) return true;
    else return false;
  }

  selectionCreate() {
    this.submitted = true;
    if(this.form.invalid) return;
    let project = ProjectForm.createObjectFromForm(this.form);
    project.awork_project.name = project.awork_project.name + " - " + this.customer.company_name;
    this.pService.createProject(project).subscribe((result) => {
      if(result){
        result.awork_project.project_typ.sections = project.sections;
        this.createProjectSuccesEmitter.emit(result);
      }
    })
  }

  // createObjectProject(): Project {
  //   let billingAddress;
  //   if(this.form.controls['add_different_address'].value == true){
  //     billingAddress = new BillngAddress(
  //       this.form.controls['billing_company_name'].value,
  //       new Address(
  //         this.form.controls['street'].value,
  //         this.form.controls['postal_code'].value,
  //         this.form.controls['city'].value,
  //       ),
  //       this.form.controls['atu'].value,
  //       null,
  //     );
  //     billingAddress.contact_person = this.form.controls['contact_address'].value;
  //   }
  //   let aworkProject = new AworkProject(
  //     null,
  //     this.form.controls['project_name'].value,
  //     this.form.controls['project_description'].value,
  //     this.form.controls['project_typ'].value,
  //     null,
  //     this.form.controls['tags'].value,
  //     this.form.controls['teams'].value,
  //   );
  //   let sections = [];
  //   for(let section of this.form.controls['sections'].value){
  //     let sectionRows = [];
  //     for(let row of section.controls['section_rows'].value){
  //       sectionRows.push(new SectionRow(
  //         row.controls['section_row_id'].value,
  //         row.controls['type'].value,
  //         row.controls['type'].value == "date" ? (row.controls['data_value'].value && row.controls['data_value'].value != 0 ? new Date(row.controls['data_value'].value).getTime() : null) : row.controls['data_value'].value,
  //         row.controls['title'].value,
  //       ))
  //     }
  //     sections.push(new Section(
  //       section.controls['section_id'].value,
  //       section.controls['section_name'].value,
  //       sectionRows
  //     ))
  //   }
  //   let obj = new Project(
  //     this.form.controls['project_id'].value,
  //     this.form.controls['customer_id'].value,
  //     billingAddress,
  //     [],
  //     sections,
  //     aworkProject,
  //     this.form.controls['status'].value,
  //     this.form.controls['creation_date'].value,
  //     this.form.controls['contract_start_date'].value
  //   )
  //   obj.contact_persons = this.form.controls['contacts'].value;
  //   return obj;
  // }

  cancelCreateAction() {
    if(this.changeDetected){
      this.dService.openQuery(
        {
          title: 'Verwerfen',
          message: 'Wenn Sie fortsetzen gehen alle Änderungen verloren.',
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Verwerfen',
          typ: 'cancel_project_create',
          showClose: true,
        }
      );
    } else {
      this.cancelCreate();
    }
  }
  cancelCreate() {
    this.form.reset();
    this.cancelEmitter.emit();
  }

  handleRequirements(requirement: any) {
    requirement.isSelected = !requirement.isSelected;
    if(requirement.isSelected){
      this.form.controls['additional_requirements'].value.push(requirement);
    } else {
      let index = this.form.controls['additional_requirements'].value.findIndex((p: any) => p.requirement_id == requirement.requirement_id);
      if(index > -1) this.form.controls['additional_requirements'].value.splice(index,1);
    }
  }

  selectionTyp(typ: ProjectType) {
    this.form.controls['project_typ'].setValue(typ);
    let el = document.getElementById('typ_disabled');
    let el2 = document.getElementById('typ_disabled_2');
    if(el && el2){
      el.style.opacity = '0';
      el2.style.opacity = '0';
      setTimeout(() => {
        el!.style.display = 'none';
        el2!.style.display = 'none';
      }, 150);
    }
    this.form.controls['tags'].setValue([]);
    this.form.controls['sections'].setValue([]);
    for(let section of typ.sections){
      this.form.controls['sections'].value.push(ProjectForm.addSectionForm(this.formbuilder,section));
    }
  }

  mergeSections() {
    for(let section of this.selectedProject.awork_project.project_typ.sections){
      for(let form of this.form.controls['sections'].value){
        if(section.section_id == form.controls['section_id'].value){
          for(let row of section.section_rows){
            for(let rowForm of form.controls['section_rows'].value){
              if(row.section_row_id == rowForm.controls['section_row_id'].value){
                rowForm.controls['data_value'].setValue(row.data_value);
                break;
              }
            }
          }
        }
      }
    }
  }

  removeContact(contact: ContactPerson, i: number) {
    contact.isSelected = false;
    this.form.controls['contacts'].value.splice(i, 1)
  }

  createProjectForm(project?: Project) {
    this.form = ProjectForm.createProjectForm(this.formbuilder, project);
    this.form.controls['customer_id'].setValue(this.customer.customer_id);
    this.formAddressSubscription = this.form.controls['add_different_address'].valueChanges.subscribe((value) => {
      if(value == true) this.form = ProjectForm.addValidatorsToAddress(this.form);
      else this.form = ProjectForm.removeValidatorsToAddress(this.form);
    });
    this.description_char_count = 250 - this.form.controls['project_description'].value?.length;
    this.form.controls['project_description'].valueChanges.subscribe((changes) => {
      if(changes) this.description_char_count = 250 - changes.length;
      else this.description_char_count = 250;
    });
  }

  selectionUpdate() {
    if(this.form.invalid){
      this.submitted = true;
      return;
    }
    let project = ProjectForm.createObjectFromForm(this.form);
    this.pService.updateProject(project).subscribe((result) => {
      if(result){
        this.updateProjectSuccessEmitter.emit(result);
      }
    })
  }
}
