import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, forkJoin, Observable, Subscription, tap, throwError } from 'rxjs';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { Title } from 'src/app/buisness-object/customer/Title';
import { User } from 'src/app/buisness-object/user/User';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { UserService } from 'src/app/service/user/user.service';
import { ThreadService } from 'src/app/service/thread/thread.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { ProjectService } from 'src/app/service/project/project.service';
import { Project } from 'src/app/buisness-object/project/Project';
import { Clipboard } from '@angular/cdk/clipboard';
import { Thread } from 'src/app/buisness-object/thread/Thread';
import { CloneObject } from 'src/app/helper/CloneObject';
import { Section, SectionRow } from 'src/app/buisness-object/project/Section';
import { ENUMTABCUSTOMER, ENUMVIEWCUSTOMER, States } from 'src/app/helper/Enums';
import { BaseComponent } from '../BaseComponent';
import { Message } from 'src/app/buisness-object/thread/Message';
import { environment } from 'src/environments/environment';
import { getColorClass } from 'src/app/helper/HelperFunctions';
import { TicketsNewComponent } from './tickets-new/tickets-new.component';
import { MobileService } from 'src/app/service/mobile/mobile.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  public view = ENUMVIEWCUSTOMER.CUSTOMER;
  public customers: Customer[] = [];
  public customersFiltered: Customer[] = [];
  public customersFilteredTemp: Customer[] = [];
  public customersThreadsFiltered: Thread[] = [];
  public notificationTickets: Thread[] = [];
  public users: User[] = [];
  // public filter = new FilterCustomer();
  public selectedUser!: User;
  public amountFilteredCustomer!: number;
  public selectedCustomer: Customer | undefined;
  public selectedProject: Project | undefined;
  public pathSub!: Subscription;
  public paramSub!: Subscription;
  public paraCustomerId!: number;
  public paraProjectId!: number;
  public isEdit = false;
  public showInterface = 1;
  // public showTab = 1;
  public tab = ENUMTABCUSTOMER.INFORMATION;
  public minimizeSidebar = false;
  public formAddressSubscription!: Subscription;
  public dialogQuerySubsription!: Subscription;
  public closeMobileMenuSubscription!: Subscription;
  public filter = {
    users: [],
    status: [],
    classification: [],
    sort_after: 1
  }
  public amountOpenThreads = 0;
  public showRestoreDialog = false;
  public getColor = getColorClass;
  public currentUser: User;
  public selectedThread: Thread;
  public showCreateTicket = false;
  public showView = false;
  public showLastAppointments = false;
  public customerDataLoaded = false;
  public showPhoneMenu = false;

  constructor(
    private cService: CustomerService,
    private uService: UserService,
    private locationRoute: Location,
    private activateRoute: ActivatedRoute,
    private tService: ThreadService,
    private dService: DialogService,
    private pService: ProjectService,
    private mService: MobileService,
    private router: Router,
    public clip: Clipboard,
  ) {
    super();
  }

  ngOnInit(): void {
    this.setUp();
    this.setDialogSubscription();
  }

  ngOnDestroy(): void {
    if(this.formAddressSubscription) this.formAddressSubscription.unsubscribe();
    if(this.closeMobileMenuSubscription) this.closeMobileMenuSubscription.unsubscribe();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.dialogQuerySubsription) this.dialogQuerySubsription.unsubscribe();
    if(this.pathSub) this.pathSub.unsubscribe();
    if(this.paramSub) this.paramSub.unsubscribe();
  }

  ngAfterViewInit(): void {
    const menuButton = document.getElementById("btn_menu");
    if(menuButton) {
      menuButton.addEventListener("click", (event) => {
        this.showPhoneMenu = !this.showPhoneMenu;
      })
    }
  }

  async setUp() {
    await this.requestDataServerside().toPromise();
    this.applyFilter();
    this.setRouteListener();
    this.showView = true;
  }

  requestDataServerside(): Observable<any> {
    return forkJoin({
      customers: this.cService.getCustomers(true),
      users: this.uService.getUsers()
    }).pipe(
      tap((result) => {
        if(result) {
          this.customers = result.customers;
          this.customersFiltered = this.setCustomersStatus(result.customers);
          this.users = result.users;
        }
      }),
      catchError((error) => {
        console.error(error);
        return throwError(error);
      })
    );
  }

  async setRouteListener() {
    this.pathSub = this.activateRoute.url.subscribe((url) => {
      if(url && url[1] && url[1].path == 'create'){
        this.openCreateCustomer();
      }
    });
    this.paramSub = this.activateRoute.queryParams.subscribe((params) => {
      if(params){
        const ticketId = params['ticketId'];
        const customerId = params['customerId'];
        const projectId = params['projectId'];
        if(ticketId){
          this.tService.getTicket(ticketId).subscribe((result) => {
            if(result){
              this.paraCustomerId = result.customer_id;
              this.paraProjectId = result.project_id;
              if(this.paraCustomerId){
                this.selectedProject = null;
                this.showView = false;
                this.showPhoneMenu = false;
                if(this.customerDataLoaded && this.selectedCustomer?.customer_id == this.paraCustomerId){
                  if(this.paraProjectId){
                    this.openProject(this.paraProjectId);
                  } else {
                    this.tab = ENUMTABCUSTOMER.INFORMATION;
                    this.view = ENUMVIEWCUSTOMER.CUSTOMER;
                    this.selectedProject = null;
                    this.selectedThread = null;
                  }
                  this.showView = true;
                } else {
                  for(const customer of this.customers){
                    if(customer.customer_id == this.paraCustomerId){
                      this.selectionCustomer(customer);
                      break;
                    }
                  }
                }
              }
            }
          })
        } else if(customerId){
          this.paraCustomerId = customerId;
          this.paraProjectId = projectId;
          this.selectedProject = null;
          this.showView = false;
          this.showPhoneMenu = false;
          if(this.customerDataLoaded && this.selectedCustomer?.customer_id == this.paraCustomerId){
            if(this.paraProjectId){
              this.openProject(this.paraProjectId);
            } else {
              this.tab = ENUMTABCUSTOMER.INFORMATION;
              this.view = ENUMVIEWCUSTOMER.CUSTOMER;
              this.selectedProject = null;
              this.selectedThread = null;
            }
            this.showView = true;
          } else {
            for(const customer of this.customers){
              if(customer.customer_id == this.paraCustomerId){
                this.selectionCustomer(customer);
                break;
              }
            }
          }
        }
      }
    })
  }

  openProject(projectId: number) {
    if(this.selectedProject && this.selectedProject.project_id == projectId){
      this.view = ENUMVIEWCUSTOMER.PROJECT;
      return;
    }
    for(const project of this.selectedCustomer.projects){
      if(project.project_id == projectId){
        for(let ticket of project.threads){
          ticket.project_id = project.project_id;
          for(let user of this.users){
            if(ticket.userId == user.userId){
              ticket.user = user;
              break;
            }
          }
        }
        this.selectedProject = project;
        this.view = ENUMVIEWCUSTOMER.PROJECT;
        break;
      }
    }
  }

  setDialogSubscription() {
    this.dialogQuerySubsription = this.dService.closeDialogQuery$.subscribe((value) => {
      if(value){
        if(value.typ == 'delete_customer' && value.submit_value == true){
          this.deleteCustomer(this.selectedCustomer.customer_id);
        } else if(value.typ == 'delete_project' && value.submit_value == true){
          this.deleteProject(this.selectedProject.project_id);
        }
      }
    });
    this.closeMobileMenuSubscription = this.mService.closeMobileMenuQuery$.subscribe((value) => {
      this.showPhoneMenu = false;
    });
  }

  setListenersCustomerView() {
    let actionButton = document.querySelector('#action_menu');
    if(actionButton){
      actionButton.addEventListener("mouseenter", this.scrollToTopDetails)
    }
  }

  scrollToTopDetails() {
    const element = document.getElementById('customer_body');
    if(element) element.scrollTop = 0;
  }


  openCreateCustomer() {
    this.selectedProject = null;
    this.selectedThread = null;
    this.showInterface = 2;
    this.showView = true;
  }
  openCreateProject() {
    this.selectedThread = null;
    this.selectedProject = null;
    this.showInterface = 3;
    this.showView = true;
  }

  public setCustomersStatus(customers: Customer[]): Customer[] {
    return customers;
  }

  scrollToTop() {
    const elementList = document.querySelectorAll('#sidebar_customer_list');
    const element = elementList[0] as HTMLElement;
    if(element) element.scrollTop = 0;
  }

  filterStatus(typ: number) {
    this.customersFiltered = [];
    this.customersFiltered = this.customers.filter((cutomer) => {
      return cutomer.customer_typ == typ;
    });
  }
  filterPostalCode(code: number) {
    let temp = this.customersFiltered;
    this.customersFiltered = [];
    this.customersFiltered = temp.filter((customer) => {
      return Number(customer.billing_address.address.postalCode) == code;
    })
  }

  filterSeller(seller: User) {
    let temp = this.customersFiltered;
    this.customersFiltered = [];
    this.customersFiltered = temp.filter((customer) => {
      return customer.seller_id == seller.userId;
    })
  }

  sortCustomers() {
    if(this.filter.sort_after == 1) this.customersFiltered.sort((a,b) => a.company_name.localeCompare(b.company_name));
    else if(this.filter.sort_after == 3) this.customersFiltered.sort((a,b) => a.billing_address.address.postalCode.localeCompare(b.billing_address.address.postalCode));
    else if(this.filter.sort_after == 2){
      this.customersFiltered.sort((a,b) => {
        if(a.last_appointments[a.last_appointments.length - 1] > b.last_appointments[b.last_appointments.length - 1]) return 1;
        if(a.last_appointments[a.last_appointments.length - 1] < b.last_appointments[b.last_appointments.length - 1]) return -1;
        return 0;
      });
    }
  }

  filterName(){
    this.customersFiltered.sort((a,b) => a.company_name.localeCompare(b.company_name));
  }

  filterAlphabetical(){
    this.customersFiltered.sort((a,b) => a.company_name.localeCompare(b.company_name));
  }

  filterCustomerAfterSeller(): Customer[] {
    let customers = this.customers.filter((customer) => {
      this.customersFiltered = [];
      return customer.seller_id.toString().includes(this.selectedUser.userId.toString());
    });
    return customers;
  }

  async selectionCustomer(customer: Customer) {
    this.showInterface = 1;
    this.customersThreadsFiltered = [];
    this.selectedCustomer = null;
    await this.requestProjectDataServerside(customer).toPromise();
    this.mergeSeller();
    this.mergeProjectsToContact()
    this.setListenersCustomerView();
    this.sortProjects();
    if(this.paraProjectId){
      this.openProject(this.paraProjectId);
    } else {
      this.tab = ENUMTABCUSTOMER.INFORMATION;
      this.view = ENUMVIEWCUSTOMER.CUSTOMER;
      this.selectedProject = null;
      this.selectedThread = null;
    }
    this.showView = true;
    // forkJoin({
    //   projects: this.pService.getProjects(customer.customer_id),
    //   notifyThreads: this.tService.getNotifications()
    // }).subscribe((result) => {
    //   if(result){
    //     this.customerDataLoaded = true;
    //     this.selectedCustomer = customer;
    //     this.paraCustomerId = this.selectedCustomer.customer_id;
    //     this.selectedCustomer.projects = result.projects;
    //     this.notificationTickets = result.notifyThreads;
    //     this.selectedCustomer.sections.sort((a,b) => a.section_name.localeCompare(b.section_name));
    //   }
    // });
  }

  requestProjectDataServerside(customer: Customer): Observable<any> {
    return forkJoin({
      projects: this.pService.getProjects(customer.customer_id),
      //notifyThreads: this.tService.getNotifications()
    }).pipe(
      tap((result) => {
        if(result) {
          this.customerDataLoaded = true;
          this.selectedCustomer = customer;
          this.paraCustomerId = this.selectedCustomer.customer_id;
          this.selectedCustomer.projects = result.projects;
          //this.notificationTickets = result.notifyThreads;
          this.selectedCustomer.sections.sort((a,b) => a.section_name.localeCompare(b.section_name));
        }
      }),
      catchError((error) => {
        console.error(error);
        return throwError(error);
      })
    );
  }

  closeProject() {
    var url = this.router.createUrlTree([], { relativeTo: this.activateRoute, queryParams: { customerId: this.selectedCustomer!.customer_id } }).toString();
    this.router.navigateByUrl(url);
    this.view = ENUMVIEWCUSTOMER.CUSTOMER;
  }

  removeTicketNotification(thread: Thread) {
    thread.notifyOpen = false;
    this.tService.updateThread(thread).subscribe((result) => {
      if(result){
        let index = this.selectedCustomer.threads.findIndex(t => t.threadId == result.threadId);
        if(index > -1) this.selectedCustomer.threads[index].notifyOpen = false;
        this.tService.changeNotificationTickets(thread);
      }
    })
  }

  mergeSeller() {
    for(let user of this.users){
      if(this.selectedCustomer.seller_id == user.userId) this.selectedCustomer.seller = user;
    }
  }

  mergeProjectsToContact() {
    for(let contact of this.selectedCustomer.contact_persons){
      for(let p of this.selectedCustomer.projects){
        if(p.contact_persons.findIndex(c => c.contact_id == contact.contact_id) > -1) contact.projects.push(p);
      }
    }
  }

  prepareThreads() {
    this.amountOpenThreads = 0;
    if(this.selectedCustomer.threads && this.selectedCustomer.threads.length > 0){
      for(let ticket of this.selectedCustomer!.threads){
        if(!ticket.resolved) this.amountOpenThreads++;
        for(let user of this.users){
          if(ticket.userId == user.userId) ticket.user = user;
          if(ticket.resolved_by_user_id == user.userId) ticket.resolved_by_user = user;
          for(let msg of ticket.messages){
            if(msg.userId == user.userId){
              msg.user = user;
            }
          }
          if(this.selectedCustomer.seller_id == user.userId) this.selectedCustomer.seller = user;
          if(user.userId == Number(localStorage.getItem('user_id'))) this.currentUser = user;
        }
      }
    }
    for(let t of this.selectedCustomer.threads){
      this.customersThreadsFiltered.push(CloneObject.deepCopy(t))
    }
    this.sortThreads();
  }

  sortThreads(noReassigne?: boolean) {
    // if(!noReassigne){
    //   this.customersThreadsFiltered = [];
    //   for(let t of this.selectedCustomer.threads){
    //     this.customersThreadsFiltered.push(CloneObject.deepCopy(t))
    //   }
    // }
    this.customersThreadsFiltered.sort((a, b) => {
      if(a.resolved && b.resolved) return a.createdDate > b.createdDate ? -1 : 1;
      else if(a.resolved && !b.resolved) return 1;
      else if(!a.resolved && b.resolved) return -1;
      else if(!a.resolved && !b.resolved) return a.createdDate > b.createdDate ? -1 : 1;
      return 0;
    })
  }

  sortProjects() {
    this.selectedCustomer.projects.sort((a, b) => {
      if(States[a.awork_project.status] > States[b.awork_project.status]) return 1;
      if(States[a.awork_project.status] < States[b.awork_project.status]) return -1;
      return 0;
    })
  }

  searchCustomer(searchValue: string){
    this.customersFiltered = [];
    if(searchValue && searchValue.length > 1){
      this.customersFiltered = this.customersFilteredTemp.filter((customer) => {
      const matchesProjectName = customer.projects?.some(project =>
        project.awork_project.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      const matchesSearchTerm = customer.search_terms?.some(term =>
        term.toLowerCase().includes(searchValue.toLowerCase())
      );
      return customer.company_name.toLowerCase().startsWith(searchValue.toLowerCase()) ||
             customer.company_name.toLowerCase().includes(searchValue.toLowerCase()) ||
             customer.seller_id.toString().includes(searchValue.toLowerCase()) ||
             customer.website_url?.toLowerCase().includes(searchValue.toLowerCase()) ||
             customer.customer_id.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
             customer.billing_address.address.postalCode?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
             this.searchIncludesContact(customer, searchValue) ||
             matchesProjectName ||
             matchesSearchTerm;
      })
    } else {
      this.customersFiltered = this.customersFilteredTemp;
    }
    this.sortCustomers();
  }

  searchIncludesContact(customer: Customer, value: string): boolean {
    for(let c of customer.contact_persons){
      return c.firstName.toLowerCase().includes(value) ||
             c.lastName.toLowerCase().includes(value) ||
            (c.firstName + ' ' + c.lastName).toLowerCase().includes(value);
    }
    return false;
  }


  createCustomerSuccess(customer: Customer) {
    //this.customers.push(customer);
    this.applyFilter();
    this.filter
    this.isEdit = false;
    this.showInterface = 1;
    this.selectionCustomer(customer)
  }
  updateCustomerSuccess(customer: Customer) {
    let i = this.customers.findIndex(c => c.customer_id == customer.customer_id);
    if(i > -1) this.customers[i] = customer;
    let iFiltered = this.customersFiltered.findIndex(c => c.customer_id == customer.customer_id);
    if(iFiltered > -1) this.customersFiltered[iFiltered] = customer;
    this.showInterface = 1;
    this.selectionCustomer(customer);
  }

  createProjectSuccess(project: Project) {
    this.selectedCustomer.projects.push(project);
    this.sortProjects();
    this.selectedProject = null;
    this.view = ENUMVIEWCUSTOMER.CUSTOMER;
    this.showInterface = 1;
  }
  updateProjectSuccess(project: Project) {
    let i = this.selectedCustomer.projects.findIndex(p => p.project_id == project.project_id);
    if(i > -1) this.selectedCustomer.projects[i] = project;
    this.sortProjects();
    this.selectedProject = project;
    this.view = ENUMVIEWCUSTOMER.PROJECT;
    this.showInterface = 1;
  }

  cancelCreate() {
    if(this.selectedCustomer) this.locationRoute.replaceState('/customers?customerId='+this.selectedCustomer.customer_id);
    else this.locationRoute.replaceState('/customers');
    this.showInterface = 1;
  }

  openEdit() {
    if(this.selectedProject){
      this.showInterface = 3;
    } else {
      this.showInterface = 2;
    }
    this.isEdit = true;
  }

  selectionProject(project: Project) {
      if(project){
        this.router.navigate(['customers'], {
          queryParams: {
            customerId: this.selectedCustomer.customer_id,
            projectId: project.project_id
          }
        });
      }
  }

  goBackAction() {
    if(this.selectedProject) this.selectedProject = undefined;
    if(this.selectedThread){
      this.selectedThread = undefined;
      this.tab = ENUMTABCUSTOMER.INFORMATION;
    }
    this.showCreateTicket = false;
  }

  copyText(str: string){
    this.clip.copy(str);
    this.dService.showNotification({
      title: 'Kopiert!',
      message: str,
      success: true
    });
  }
  openLink(str: string) {
    let pattern = '^(https?:\\/\\/)?' + // protocol
        '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
        '[a-zA-Z]{2,13})' + // extension
        '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
        '|localhost)' + // OR localhost
        '(\\:\\d{1,5})?' + // port
        '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
        '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
        '(\\#[-a-zA-Z&\\d_]*)?$'; // fragment locator
    let regex = new RegExp(pattern);
    if(str && regex.test(str)){
      window.open('https://'+str, '_blank')!.focus();
    } else {
      this.dService.showNotification({
        title: 'Fehlgeschlagen',
        message: 'Link kann nicht geöffnet werden.',
        success: false
      });
    }
  }

  openViewInfo() {
    this.selectedProject = null;
    this.selectedThread = null;
    this.showCreateTicket = false;
    this.tab = ENUMTABCUSTOMER.INFORMATION;
  }
  openViewTickets() {
    let el = document.getElementById('input_search_tickets') as HTMLInputElement;
    if(el) el.value = "";
    this.selectedProject = null;
    this.selectedThread = null;
    this.customersThreadsFiltered = this.selectedCustomer.threads;
    this.sortThreads();
    this.showCreateTicket = false;
    this.tab = ENUMTABCUSTOMER.TICKETS;
  }
  openViewDocu() {
    this.selectedProject = null;
    this.selectedThread = null;
    this.showCreateTicket = false;
    this.tab = ENUMTABCUSTOMER.DOCUMENTATION;
  }

  openTicket(ticket: Thread) {
    this.router.navigate(['/tickets', ticket.threadId]);
  }

  selectionDeleteCustomer() {
    if(this.selectedCustomer){
      this.dService.openQuery(
        {
          title: 'ACHTUNG! Kunde löschen',
          message: `Kunde "${this.selectedCustomer?.company_name}" wird unwiederuflich gelöscht. Sind Sie sicher das Sie fortfahren möchten?`,
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Löschen',
          typ: 'delete_customer',
          submit_value: true,
          showClose: true,
          is_choise: true
        }
      );
    }
  }

  deleteCustomer(id: number) {
    this.cService.deleteCustomer(id).subscribe((success) => {
      if(success){
        let index = this.customers.findIndex(c => c.customer_id == id);
        if(index > -1) this.customers.splice(index,1);
        this.customersFiltered = this.customers;
        this.selectedCustomer = undefined;
      }
    })
  }

  openTicketCreate() {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => this.router.navigate(['tickets'], { queryParams: { customerId: this.selectedCustomer!.customer_id } }));
  }

  selectionCreateNewProject() {
    this.showInterface = 3;
  }


  applyFilter() {
    this.customersFiltered = [];
    this.customersFiltered = this.customers.filter(customer => {
      let found = true;
      if(!this.includesUser(customer)) found = false;
      if(!this.includesStatus(customer)) found = false;
      if(!this.includesClassification(customer)) found = false;
      return found;
    });
    this.customersFilteredTemp = this.customersFiltered;
    this.sortCustomers();
    setTimeout(() => {
      // this.scrollToTop();
      this.setSideBarHeight();
    }, 50);
  }

  setSideBarHeight() {
    let filter = document.getElementById('setted_filter_wrapper');
    let list = document.getElementById('sidebar_customer_list');
    if(filter && list){
      let filterHeight = filter.offsetHeight;
      list.setAttribute('style', 'height: calc(100vh - '+'55px'+' - 81px - 124px - '+ filterHeight +'px)');
    }
  }

  includesUser(customer: Customer): boolean {
    if(this.filter.users.length == 0) return true;
    else if(this.filter.users.findIndex(user => user.userId == customer.seller_id) > -1) return true;
    else return false;
  }

  includesStatus(customer: Customer): boolean {
    if(this.filter.status.length == 0) return true;
    else if(this.filter.status.findIndex(status => status == customer.customer_typ) > -1) return true;
    else false;
  }

  includesClassification(customer: Customer): boolean {
    if (this.filter.classification.length === 0) return true;
    return this.filter.classification.some(classification =>
      ((classification == 1) && (customer.is_waymark == true)) ||
      ((classification == 2) && (customer.is_zweispurig == true)) ||
      ((classification == 3) && (customer.is_autobase == true)) ||
      ((classification == 4) && (customer.is_autoscout == true))
    );
  }

  removeFilterStatus(status: number) {
    let index = this.filter.status.findIndex(s => s  == status);
    if(index > -1) this.filter.status.splice(index,1);
    this.applyFilter();
  }
  removeFilterSeller(user: any) {
    let index = this.filter.users.findIndex(u => u.userId == user.userId);
    if(index > -1) this.filter.users.splice(index,1);
    this.applyFilter();
  }

  removeFilterClassification(classification: any) {
     let index = this.filter.classification.findIndex(c => c == classification);
     if(index > -1) this.filter.classification.splice(index,1);
    this.applyFilter();
  }

  showContactPersons(): boolean {
    let index = this.selectedProject.contact_persons.findIndex(c => c.contact_id == this.selectedProject.billing_address?.contact_person.contact_id);
    if(index > -1) return this.selectedProject.contact_persons.length - 1 > 0;
    else return this.selectedProject.contact_persons.length > 0;
  }
  showSectionsProject(): boolean {
    for(let section of this.selectedProject.awork_project.project_typ.sections){
      for(let row of section.section_rows){
        if(row.data_value != null) return true;
      }
    }
    return false;
  }
  showSingleSectionProject(section: Section) : boolean {
    for(let row of section.section_rows){
      if(row.data_value != null) return true;
    }
    return false;
  }
  showSectionsCustomer() : boolean {
    for(let section of this.selectedCustomer.sections){
      for(let row of section.section_rows){
        if(row.data_value != null) return true;
      }
    }
    return false;
  }
  showSingleSectionCustomer(section: Section) : boolean {
    for(let row of section.section_rows){
      if(row.data_value != null) return true;
    }
    return false;
  }

  selectionArchive() {
    if(this.selectedProject){
      this.actionArchiveProject();
    } else {
      if(this.selectedCustomer.customer_typ == 3) this.showRestoreDialog = true;
      else this.actionChangeStatusCustomer(3);
    }
  }

  restoreCustomer(typ: number) {
    this.actionChangeStatusCustomer(typ);
  }

  actionArchiveProject() {
    let temp = CloneObject.deepCopy(this.selectedProject);
    temp.status = (temp.status == 1 ? 1 : 1);
    this.pService.updateProject(temp).subscribe((result) => {
      if(result){
        this.selectedProject = result;
        let index =  this.selectedCustomer.projects.findIndex(p => p.project_id == result.project_id);
        if(index > -1) this.selectedCustomer.projects[index] = result;
      }
    })
  }

  actionChangeStatusCustomer(typ: number) {
    let temp = CloneObject.deepCopy(this.selectedCustomer);
    temp.customer_typ = typ;
    this.cService.updateCustomer(temp).subscribe((result) => {
      if(result){
        let index = this.customers.findIndex(c => c.customer_id == result.customer_id);
        if(index > -1) this.customers[index] = result;
        let indexFiltered = this.customersFiltered.findIndex(c => c.customer_id == result.customer_id);
        if(indexFiltered > -1) this.customersFiltered[indexFiltered] = result;
        result.projects = this.selectedCustomer.projects;
        this.selectedCustomer = result;
        this.showRestoreDialog = false;
      }
    })
  }

  successResolveTicket(thread: Thread) {
    let index = this.selectedCustomer.threads.findIndex(t => t.threadId == thread.threadId);
    if(index != -1) this.selectedCustomer.threads[index].resolved = thread.resolved;
    let index2 = this.customersThreadsFiltered.findIndex(t => t.threadId == thread.threadId);
    if(index2 != -1) this.customersThreadsFiltered[index2].resolved = thread.resolved;
    this.amountOpenThreads--;
    this.selectedThread = null;
    this.sortThreads();
    this.dService.showNotification({
      title: 'Ticket gelöst.',
      message: this.dService.getTitle(),
      success: true
    });
  }
  successRestoreTicket(thread: Thread) {
    let index = this.selectedCustomer.threads.findIndex(t => t.threadId == thread.threadId);
    if(index != -1) this.selectedCustomer.threads[index].resolved = thread.resolved;
    let index2 = this.customersThreadsFiltered.findIndex(t => t.threadId == thread.threadId);
    if(index2 != -1) this.customersThreadsFiltered[index2].resolved = thread.resolved;
    this.amountOpenThreads++;
    this.sortThreads();
    this.dService.showNotification({
      title: 'Ticket wiederhergestellt',
      message: this.dService.getPfui(),
      success: true
    });
  }

  successCreateTicket(thread: Thread) {
    this.selectedCustomer.threads.push(thread);
    this.sortThreads();
    this.showCreateTicket = false;
    this.dService.showNotification({
      title: 'Ticket erstellt',
      message: 'Das neue Ticket wurde erfolgreich erstellt.',
      success: true
    });
  }
  successCreateMessageEmitter(thread: Thread) {
    let index = this.selectedCustomer.threads.findIndex(t => t.threadId == thread.threadId);
    if(index != -1) this.selectedCustomer.threads[index] = thread;
    let index2 = this.customersThreadsFiltered.findIndex(t => t.threadId == thread.threadId);
    if(index2 != -1)  this.customersThreadsFiltered[index2] = thread;
    this.sortThreads();
  }

  searchTicketsAction(value: string) {
    this.customersThreadsFiltered = this.selectedCustomer.threads.filter((thread) => {
      if(value && value.length > 2){
        this.customersThreadsFiltered = [];
        return thread.user.firstName.toLowerCase().includes(value.toLowerCase()) ||
               thread.user.lastName.toLowerCase().includes(value.toLowerCase()) ||
               (thread.user.firstName + ' ' + thread.user.lastName).toLowerCase().includes(value.toLowerCase()) ||
               this.messagesIncludeSearch(value, thread.messages) ||
               thread.subject.toLowerCase().includes(value.toLowerCase());;
      } else {
        return this.customersThreadsFiltered;
      }
    });
    this.sortThreads(true)
  }

  messagesIncludeSearch(value: string, messages: Message[]): boolean {
    for(let msg of messages){
      if(msg.message.toLowerCase().includes(value.toLowerCase())) return true;
    }
    return false;
  }

  selectionThread(thread: Thread) {
    this.selectedProject = null;
    if(this.isNotifyTicket(thread)){
      this.removeTicketNotification(thread);
    }
    this.selectedThread = thread;
    this.showCreateTicket = false;
    this.tab = ENUMTABCUSTOMER.TICKETS;
  }

  isNotifyTicket(thread: Thread): boolean {
    for(let t of this.notificationTickets){
      if(thread.threadId == t.threadId) return true;
    }
    return false;
  }

  selectionCloseThread() {
    this.locationRoute.replaceState('/customers?customerId='+this.selectedCustomer.customer_id);
    this.selectedThread = null;
    this.showCreateTicket = false;
  }

  selectionOpenCreateTicket() {
    this.showCreateTicket = true;
    this.selectedThread = null;
  }

  setSectionsCustomerBorder(index: number): boolean {
    if(this.selectedCustomer.sections[index] && this.selectedCustomer.sections[index].section_rows.findIndex(r => r.data_value != null) > -1) return false;
    else return true;
  }
  setSectionsProjectBorder(index: number): boolean {
    if(this.selectedProject.sections[index] && this.selectedProject.sections[index].section_rows.findIndex(r => r.data_value != null) > -1) return false;
    else return true;
  }

  selectionSection(row: SectionRow) {
    if(row.title.toLowerCase().includes('password')) this.copyText(row.data_value!)
    else if(row.title.toLowerCase().includes('link')) row.openUrl();
  }

  selectionMinimize() {
    this.minimizeSidebar = !this.minimizeSidebar;
  }

  hasGodAccess(): boolean {
    if(environment.api.includes("test-") && (this.currentUser?.userId == 46 || this.currentUser?.userId == 47)){
      return true;
    } else if(!environment.api.includes("test-") && (this.currentUser?.userId == 46 || this.currentUser?.userId == 47)) {
      return true;
    }
    return false;
  }

  selectionDeleteProject() {
    if(this.selectedProject){
      this.dService.openQuery(
        {
          title: 'ACHTUNG! Projekt löschen',
          message: 'Bist du sicher das du das Projekt "'+ this.selectedProject?.awork_project.name +'" löschen möchtest?',
          btn_cancel_txt: 'Abbrechen',
          btn_submit_txt: 'Löschen',
          typ: 'delete_project',
          submit_value: true,
          showClose: true,
          is_choise: true
        }
      );
    }
  }

  deleteProject(id: number) {
    this.pService.deleteProject(id).subscribe((success) => {
      if(success){
        let i = this.selectedCustomer.projects.findIndex(p => p.project_id == id);
        if(i > -1) this.selectedCustomer.projects.splice(i,1)
        this.view = ENUMVIEWCUSTOMER.CUSTOMER;
        this.showInterface = 1;
        this.selectedProject = null;
      }
    })
  }
}
