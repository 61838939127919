<div id="sidebar-list">
  <div id="open_pins_list_container">
    <div id="elements_container">
      <div *ngFor="let calls of groupedCallsByDueDate; let i = index;">
        <div class="list_title_inner" (click)="showListAction(i)" >{{calls[0].due_date | date: 'EEEE, dd.MMM.YYYY'}}
          <img class="list_title_inner_icon" [ngStyle]="{'transform': !hideList(i) ? 'rotate(180deg)' : ''}" src="./assets/arrowdropdown.svg">
        </div>
        <div *ngIf="!hideList(i)">
          <div *ngFor="let call of calls; let i = index">
            <div class="list_container">
              <app-phonelist-sidebar-item
                [active]="call.call_id == paramID"
                [call]="call"
              ></app-phonelist-sidebar-item>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
