import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { User } from 'src/app/buisness-object/user/User';

export function passwordValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;

  if(!value) return null;

  const hasLowerCase = /[a-z]/.test(value);
  const hasUpperCase = /[A-Z]/.test(value);
  const hasNumber = /\d/.test(value);
  const hasSymbol = /[\W_]/.test(value);

  const conditionsMet = [hasLowerCase, hasUpperCase, hasNumber, hasSymbol].filter(Boolean).length;

  if(value.length >= 8 && conditionsMet >= 3){
    return null;
  }

  return {passwordStrength: 'Passwort muss mindestens aus 8 Zeichen bestehen und 3 dieser 4 Kriterien beinhalten: Kleinbuchstabe, Großbuchstabe, Sonderzeichen, Zahl'};
}


@Component({
  selector: 'app-dialog-password-change',
  templateUrl: './dialog-password-change.component.html',
  styleUrls: ['./dialog-password-change.component.scss']
})
export class DialogPasswordChangeComponent implements OnInit {

  @Output() closeDialogEmitter: EventEmitter<void> = new EventEmitter<void>();
  @Output() changedPasswordEmitter: EventEmitter<any> = new EventEmitter<any>();
  public showPassword: boolean = false;
  public showPasswordNew: boolean = false;
  public submitted: boolean = false;
  public form: FormGroup = null;

  public hideInputImage: string = '../../../../../assets/inputhide.svg';
  public showInputImage: string = '../../../../../assets/inputshow.svg';

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      old_password: [null, Validators.required],
      new_password: [null, [passwordValidator, Validators.required]],
    })
  }

  submitFilterForm() {
    this.submitted = true;
    if(this.form.invalid){
      return;
    }

    const passwordData = {
      old_password: this.form.controls.old_password?.value,
      new_password: this.form.controls.new_password?.value,
    }
    this.changedPasswordEmitter.emit(passwordData);

  }

  close() {
    this.closeDialogEmitter.emit();
  }

  tooglePassword() {
    this.showPassword = !this.showPassword;
  }

}
