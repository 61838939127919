import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, forkJoin, lastValueFrom, Observable, tap} from 'rxjs';
import { Call, TIMETYPE } from 'src/app/buisness-object/call/Call';
import { CallForm } from 'src/app/buisness-object/call/form/CallForm';
import { ContactPerson } from 'src/app/buisness-object/customer/ContactPerson';
import { Customer } from 'src/app/buisness-object/customer/Customer';
import { CustomerForm } from 'src/app/buisness-object/customer/form/CustomerForm';
import { CustomerService } from 'src/app/service/customer/customer.service';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { PhoneCallService } from 'src/app/service/phone-call/phone-call.service';
import { DateHelper } from 'src/app/utils/DateHelper';
import { ErrorHelper } from 'src/app/utils/ErrorHelper';

@Component({
  selector: 'app-phonelist-create',
  templateUrl: './phonelist-create.component.html',
  styleUrls: ['./phonelist-create.component.scss']
})
export class PhonelistCreateComponent implements OnInit {

  @Input() call: Call;
  @Input() timeStampSets: any;
  @Output() createSuccessEmitter = new EventEmitter<Call>();
  @Output() selectionCancelEmitter = new EventEmitter<any>();
  @Output() updateSuccessEmitter = new EventEmitter<any>();

  public individualTime = false;
  public form: FormGroup;
  public contactForm: FormGroup;
  public submitted = false;
  public showDropdown = false;
  public showDropdown2 = false;
  public showDropdown3 = false;
  public showDropdown4 = false;
  public customers: Customer[] = [];
  public customer: Customer;
  public customerValues: Customer[];
  public contactPersonValues: Customer[];
  public minDate;
  public today;
  public selectPriorities = [
    { label: 'Hoch', value: 0 },
    { label: 'Mittel', value: 1 },
    { label: 'Niedrig', value: 2 }
  ];
  public selectedPriority: number = this.selectPriorities[1].value;
  public categories = [
    { label: 'Kunde', value: 1 },
    { label: 'Interessent', value: 2 },
    { label: 'Partner', value: 3 },
    { label: 'Lieferant', value: 4 },
    { label: 'Privat', value: 5 },
    { label: 'Sonstige', value: 6 },
  ];
  public selectedCategory: string;
  public contacts: ContactPerson[] = [];
  public customerChanged = false;
  public noonTimestamp = new Date().setHours(12,0,0,0);
  public afterNoonTimestamp = new Date().setHours(14,0,0,0);
  public currentContact;
  public updatedDueDate: Date;
  public customersFiltered: Customer[] = [];
  public searchTimeout: any;
  public TimeTyp = TIMETYPE;
  public selectedCustomerContacts: ContactPerson[] = [];
  public contactChanged = false;
  public timeChanged = false;

  constructor(
    private formbuilder: FormBuilder,
    private customerService: CustomerService,
    private phoneCallService: PhoneCallService,
    private dService: DialogService
  ) { }

  ngOnInit(): void {
    this.today = new Date();
    this.minDate = formatDate(new Date(),'yyyy-MM-dd','de');
    this.setUp();
    this.customersFiltered = this.customers;
    this.selectedPriority = this.selectPriorities[this.form.get('priority').value].value;
  }

  async setUp() {
    this.form = CallForm.getForm(this.formbuilder, this.call);
    this.timeChanged = false;
    this.onSelectCategory(this.categories[1]);
    this.form.get('due_date').valueChanges.subscribe((change) => {
      this.timeChanged = true;
    })
    this.form.get('time').valueChanges.subscribe((change) => {
      this.timeChanged = true;
    })
    await lastValueFrom(this.requestDataServerside());
    if(this.call){
      this.fillForEdit();
      if(this.call.contact_person_id == null){
        this.setContactValidators(false);
      }
    } else {
      this.setContactValidators(false);
    }
    this.customerValues = this.getDropdownValuesCustomer(this.customers);
  }

  fillForEdit() {
    if(this.call.customer_id){
      this.selectionCustomer(this.call.customer_id);
    }
    if(this.call.contact_person_id){
      this.selectionContact(this.call.contact_person_id);
    } else {
      this.form.get('contact_form').value.get('contact_email').setValue(this.call.contact_email);
      this.form.get('contact_form').value.get('contact_first_name').setValue(this.call.contact_first_name);
      this.form.get('contact_form').value.get('contact_last_name').setValue(this.call.contact_last_name);
      this.form.get('contact_form').value.get('contact_phone').setValue(this.call.phone);
      this.form.get('contact_form').value.get('company_name').setValue(this.call.company_name);
      this.form.get('contact_form').value.get('position').setValue(this.call.contact_position);
      this.form.get('contact_form').value.get('contact_salutation').setValue(this.call.contact_salutation);
    }
    const type = this.categories.find((c) => c.label == this.call.category);
    if(type){
      this.onSelectCategory(type);
    }
    const priority = this.selectPriorities.find((c) => c.value == this.call.priority);
    if(priority){
      this.onSelectPriority(priority);
    }
  }

  onSelectCategory(type: { label: string, value: number }): void {
    this.selectedCategory = type.label;
    this.form.get('category').setValue(type.label);
  }

  onSelectPriority(type: { label: string, value: number }): void {
    this.selectedPriority = type.value;
    this.form.get('priority').setValue(type.value);
  }

  getDropdownValuesCustomer(customers: Customer[]): any[] {
    let values = [];
    for(let value of customers){
      values.push({
        id: value.customer_id,
        name: value.company_name
      })
    }
    return values;
  }

  requestDataServerside(): Observable<any> {
    return forkJoin({
      customers: this.customerService.getCustomers(),
    }).pipe(
      tap((result) => {
        if(result) {
          this.customers = result.customers;
        }
      }),
      catchError((error) => {
        console.error(error);
        return null;
      })
    );
  }

  selectionCustomer(customerId: number) {
    const customer = this.customers.find(obj => obj.customer_id == customerId);
    this.form.get('customer_id').setValue(customerId);
    this.form.get('customer').setValue(customer);
    this.form.get('contact_form').value.get('company_name').setValue(customer.company_name);
    this.getContacts();
    this.contactChanged = false;
    this.updateInputCustomer(customer.company_name);
    this.updateInputContact('');
    this.setContactValidators(false);
  }

  getContacts() {
    //this.form.get('contact_form').setValue(CustomerForm.addContactForm(this.formbuilder, null));
    this.selectedCustomerContacts = [];
    const customer = this.customers.find(obj => obj.customer_id == this.form.get('customer_id').value);
    if(customer){
      this.selectedCustomerContacts = customer.contact_persons;
    }
  }

  selectionContact(contactId: number) {
    const contactPerson = this.form.get('customer').value.contact_persons.find(obj => obj.contact_id == contactId);
    if(contactPerson == null) {
      return;
    }
    this.form.get('contact_form').setValue(CustomerForm.addContactForm(this.formbuilder, contactPerson));
    this.form.get('contact_form').value.get('company_name').setValue(this.form.get('customer').value.company_name)
    this.setContactValidators(true);
    this.form.get('contact_person_id').setValue(contactPerson.contact_id);
    this.contactChanged = false;
    this.form.get('contact_form').value.valueChanges.subscribe((change) => {
      this.contactChanged = true;
    })
    this.updateInputContact(contactPerson.firstName + ' ' + contactPerson.lastName);
  }

  get formCustomer() {
    return this.form.get('customer_form').value;
  }

  get formContact() {
    return this.form.get('contact_form').value;
  }

  invalidForm(): boolean {
    if(this.form.invalid){
      ErrorHelper.showFormError(this.form);
      return true;
    }
    if(this.form.get('contact_form').value.invalid){
      ErrorHelper.showFormError(this.form.get('contact_form').value);
      return true;
    }
    return false;
  }

  // create Call
  async selectionCreate() {
    this.submitted = true;
    if(this.invalidForm()) {
      return;
    }
    let newCall = CallForm.getObject(this.form);
    newCall.due_date = this.getNewCallTime(newCall);
    if(this.form.get('is_contact_to_create').value == true) {
      const customer = newCall.customer;
      const newContact = CustomerForm.createContact(this.form.get('contact_form').value);
      customer.contact_persons.push(newContact);
      await lastValueFrom(this.customerService.updateCustomer(customer));
    } else if(this.contactChanged){
      const customer = this.form.get('customer').value;
      const updateContact = CustomerForm.createContact(this.form.get('contact_form').value);
      const index = customer.contact_persons.findIndex((contact) => contact.contact_id == updateContact.contact_id);
      if(index > -1){
        customer.contact_persons[index] = updateContact;
      }
      await lastValueFrom(this.customerService.updateCustomer(customer));
    }
    const result = await lastValueFrom(this.phoneCallService.createPhoneCall(newCall));
    if(!result){
      return;
    }
    this.dService.showNotification({
      title: 'Erfolgreich',
      message: 'Anruf wurde erstellt.',
      success: true
    });
    this.createSuccessEmitter.emit(result);
  }

  async selectionUpdate() {
    this.submitted = true;
    if(this.invalidForm()){
      return;
    }
    let newCall = CallForm.getObject(this.form);
    if(this.timeChanged){
      newCall.due_date = this.getNewCallTime(newCall);
    } else {
      newCall.due_date = this.call.due_date;
    }
    if(this.form.get('is_contact_to_create').value == true) {
      const customer = newCall.customer;
      const newContact = CustomerForm.createContact(this.form.get('contact_form').value);
      customer.contact_persons.push(newContact);
      await lastValueFrom(this.customerService.updateCustomer(customer));
    } else if(this.contactChanged){
      const customer = this.form.get('customer').value;
      const updateContact = CustomerForm.createContact(this.form.get('contact_form').value);
      const index = customer.contact_persons.findIndex((contact) => contact.contact_id == updateContact.contact_id);
      if(index > -1){
        customer.contact_persons[index] = updateContact;
      }
      await lastValueFrom(this.customerService.updateCustomer(customer));
    }
    this.phoneCallService.updatePhoneCall(newCall).subscribe((result) => {
      if(result){
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Anruf wurde aktualisiert.',
          success: true
        });
        this.updateSuccessEmitter.emit(result);
      }
    })
  }

  selectionCancel() {
    this.selectionCancelEmitter.emit();
  }

  selectionClearCustomer(inputclear?: boolean) {
    this.form.get('customer_id').setValue(null);
    this.form.get('customer').setValue(null);
    this.form.get('contact_form').value.get('company_name').setValue(null);
    if(this.form.get('contact_person_id').value != null){
      this.form.get('contact_person_id').setValue(null);
      this.form.get('contact_form').setValue(CustomerForm.addContactForm(this.formbuilder));
      this.updateInputContact('');
    }
    if(inputclear){
      this.updateInputCustomer('');
    }
    this.setContactValidators(false);
  }

  updateInputCustomer(value: string){
    const inputCustomer = document.getElementById('input-customer') as HTMLInputElement;
    if(inputCustomer){
      inputCustomer.value = value;
    }
  }

  updateInputContact(value: string) {
    const inputContact = document.getElementById('input-contact') as HTMLInputElement;
    if(inputContact){
      inputContact.value = value;
    }
  }

  selectionClearContact() {
    this.form.get('contact_person_id').setValue(null);
    this.form.get('contact_form').setValue(CustomerForm.addContactForm(this.formbuilder));
    if(this.form.get('customer_id').value != null){
      this.form.get('contact_form').value.get('company_name').setValue(this.form.get('customer').value.company_name)
    }
    this.form.get('is_contact_to_create').setValue(false);
    this.setContactValidators(false);
    const input = document.getElementById('input-contact') as HTMLInputElement;
    if(input){
      input.value = '';
    }
  }

  selectionContactToCreate() {
    this.form.get('is_contact_to_create').setValue(!this.form.get('is_contact_to_create').value);
    this.setContactValidators(this.form.get('is_contact_to_create').value);
  }

  setContactValidators(validate: boolean) {
    if(validate){
      this.form.get('contact_form').value.controls['contact_salutation'].addValidators(Validators.required);
      this.form.get('contact_form').value.controls['contact_salutation'].updateValueAndValidity();
      this.form.get('contact_form').value.controls['contact_first_name'].addValidators(Validators.required);
      this.form.get('contact_form').value.controls['contact_first_name'].updateValueAndValidity();
      this.form.get('contact_form').value.controls['contact_last_name'].addValidators(Validators.required);
      this.form.get('contact_form').value.controls['contact_last_name'].updateValueAndValidity();
      this.form.get('contact_form').value.controls['contact_email'].addValidators(Validators.required);
      this.form.get('contact_form').value.controls['contact_email'].updateValueAndValidity();
    } else {
      this.form.get('contact_form').value.controls['contact_salutation'].removeValidators(Validators.required);
      this.form.get('contact_form').value.controls['contact_salutation'].updateValueAndValidity();
      this.form.get('contact_form').value.controls['contact_first_name'].removeValidators(Validators.required);
      this.form.get('contact_form').value.controls['contact_first_name'].updateValueAndValidity();
      this.form.get('contact_form').value.controls['contact_last_name'].removeValidators(Validators.required);
      this.form.get('contact_form').value.controls['contact_last_name'].updateValueAndValidity();
      this.form.get('contact_form').value.controls['contact_email'].removeValidators(Validators.required);
      this.form.get('contact_form').value.controls['contact_email'].updateValueAndValidity();
    }
  }

  searchCustomers(searchValue: string){
    this.selectionClearCustomer(false);
    this.customersFiltered = [];
      if(this.customers && searchValue && searchValue.length > 1) {
        this.customersFiltered = this.customers.filter((customer) => {
          return customer.company_name?.toLowerCase().startsWith(searchValue.toLowerCase()) ||
                 customer.company_name?.toLowerCase().includes(searchValue.toLowerCase())
        })
      } else {
        this.customersFiltered = this.customers;
      }
  }

  selectionTimeTypExact() {
    if(this.form.get('time_type').value == TIMETYPE.EXACT){
      this.setTimeTyp('morning');
    } else {
      this.setTimeTyp('exact');
    }
  }

  selectionTimeTyp() {
    if(this.form.get('time_type').value == TIMETYPE.MORNING){
      this.setTimeTyp('afternoon');
    } else {
      this.setTimeTyp('morning');
    }
  }

  setTimeTyp(timeTyp: 'exact'|'morning'|'afternoon') {
    this.form.get('time_type').setValue(timeTyp == 'exact' ? TIMETYPE.EXACT : (timeTyp == 'morning' ? TIMETYPE.MORNING : TIMETYPE.AFTERNOON));
  }

  private MORNING_START = 6 * 60 * 60 * 1000;
  private MORNING_END = 12 * 60 * 60 * 1000;
  private AFTERNOON_START = 12 * 60 * 60 * 1000;
  private AFTERNOON_END = 18 * 60 * 60 * 1000;

  getNewCallTime(call: Call): number {
    if(call.time_type == TIMETYPE.EXACT){
      const time = this.form.get('time').value;
      const hour = time.split(':')[0];
      const min = time.split(':')[1];
      let date = new Date(call.due_date);
      date.setHours(hour);
      date.setMinutes(min);
      return date.getTime();
    } else if(call.time_type == TIMETYPE.MORNING){
      return this.generateUniqueTimestamp(new Date(call.due_date), this.MORNING_START, this.MORNING_END, this.timeStampSets.morningSet);
    } else {
      return this.generateUniqueTimestamp(new Date(call.due_date), this.AFTERNOON_START, this.AFTERNOON_END, this.timeStampSets.afternoonSet);
    }
  }

  generateUniqueTimestamp(date: Date, rangeStart: number, rangeEnd: number, existingTimestamps: Set<number>): number {
    const baseTimestamp = date.setHours(0, 0, 0, 0);
    for(let offset = rangeStart; offset < rangeEnd; offset += (3*60000)) {
        const candidate = baseTimestamp + offset;
        if(!existingTimestamps.has(candidate)) {
            existingTimestamps.add(candidate);
            return candidate;
        }
    }
  }
}
