<div id="action_menu">
  <img class="action_menu_button_icon" src="./assets/navdots.svg">
  <div id="action_menu_box">
    <div class="action" (click)="editEmitter.emit()">
      <svg xmlns="http://www.w3.org/2000/svg" width="12.046" height="12.045" viewBox="0 0 12.046 12.045">
        <g class="action_icon" transform="translate(-806 -144.477)">
          <path class="action_menu_icon" id="Pfad_943" data-name="Pfad 943" d="M7.192,124.8.1,131.893a.334.334,0,0,0-.1.236v1.359a.365.365,0,0,0,.365.365H1.723a.334.334,0,0,0,.236-.1l7.094-7.094a.194.194,0,0,0,0-.275L7.467,124.8A.194.194,0,0,0,7.192,124.8Z" transform="translate(806 22.669)" fill="#1166B2"/>
          <path class="action_menu_icon" id="Pfad_944" data-name="Pfad 944" d="M348.757.383a1.317,1.317,0,0,0-1.862,0l-1.619,1.622a.194.194,0,0,0,0,.275l1.587,1.587a.194.194,0,0,0,.275,0l1.619-1.619a1.317,1.317,0,0,0,0-1.862Z" transform="translate(468.903 144.479)" fill="#1166B2"/>
        </g>
      </svg>
      <label>Bearbeiten</label>
    </div>
    <div class="action" (click)="openPopupEmitter.emit('changeDate')">
      <svg xmlns="http://www.w3.org/2000/svg" width="8.233" height="8.233" viewBox="0 0 8.233 8.233">
        <g class="action_icon">
          <path class="action_menu_icon" d="M6.346.686H6.174V.515A.515.515,0,0,0,5.66,0h0a.515.515,0,0,0-.515.515V.686H3.087V.515A.515.515,0,0,0,2.573,0h0a.515.515,0,0,0-.515.515V.686H1.887A1.887,1.887,0,0,0,0,2.573V6.346A1.887,1.887,0,0,0,1.887,8.233H6.346A1.887,1.887,0,0,0,8.233,6.346V2.573A1.887,1.887,0,0,0,6.346.686Zm0,6.517H1.887a.858.858,0,0,1-.858-.858V3.43H7.2V6.346A.858.858,0,0,1,6.346,7.2Z" fill="#3b88cc"/>
        </g>
      </svg>
      <label>Verschieben</label>
    </div>
    <div class="action" (click)="openPopupEmitter.emit('changePriority')">
      <svg xmlns="http://www.w3.org/2000/svg" width="9.133" height="8.709" viewBox="0 0 9.133 8.709">
        <g class="action_icon">
          <path class="action_menu_icon" d="M-3966.935,10h-7.13a.992.992,0,0,1-.857-.485.991.991,0,0,1-.025-.983l3.566-6.709a.983.983,0,0,1,.883-.531.986.986,0,0,1,.883.531l3.565,6.709a.992.992,0,0,1-.026.984A.992.992,0,0,1-3966.935,10ZM-3970.5,7.54a.349.349,0,0,0-.349.348v.123a.35.35,0,0,0,.349.349.349.349,0,0,0,.348-.349V7.889A.349.349,0,0,0-3970.5,7.54Zm0-4.1a.349.349,0,0,0-.349.348V6.7a.349.349,0,0,0,.349.348.349.349,0,0,0,.348-.348V3.788A.349.349,0,0,0-3970.5,3.44Z" transform="translate(3975.066 -1.29)" fill="#3b88cc"/>
        </g>
      </svg>
      <label>Priorität ändern</label>
    </div>
    <div class="action" (click)="deleteEmitter.emit()">
      <svg xmlns="http://www.w3.org/2000/svg" width="11.667" height="14" viewBox="0 0 11.667 14">
        <path class="action_icon" d="M53.75,2.333H51.942A2.922,2.922,0,0,0,49.084,0H47.917a2.922,2.922,0,0,0-2.858,2.333H43.25a.583.583,0,0,0,0,1.167h.583v7.583A2.92,2.92,0,0,0,46.75,14h3.5a2.92,2.92,0,0,0,2.917-2.917V3.5h.583a.583.583,0,0,0,0-1.167ZM47.917,9.917a.583.583,0,0,1-1.167,0v-3.5a.583.583,0,0,1,1.167,0v3.5Zm2.333,0a.583.583,0,0,1-1.167,0v-3.5a.583.583,0,0,1,1.167,0ZM46.267,2.333a1.754,1.754,0,0,1,1.65-1.167h1.167a1.754,1.754,0,0,1,1.65,1.167Z" transform="translate(-42.667)" fill="#DB5844"/>
      </svg>
      <label>Löschen</label>
    </div>
  </div>
</div>
