import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DialogService } from '../dialog/dialog.service';
import { LoginService } from '../login/login.service';
import { Call } from 'src/app/buisness-object/call/Call';
import { CallFactory } from 'src/app/buisness-object/call/factory/CallFactory';


@Injectable({
  providedIn: 'root'
})
export class PhoneCallService {
  public calls$ = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private lService: LoginService,
    private dService: DialogService
    ) { }

  createPhoneCall(call: Call): Observable<Call> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    let body = call.getJSON();
    const observable = this.http.post(environment.api + '/back/phone_call', body, {headers});
    return observable.pipe(
      map((rawCall: any) => {
        const call = CallFactory.jsonFactoryOne(rawCall.phone_call);
        this.dService.showNotification({
          title: 'Anruf erstellt',
          message: 'Es wurde erfolgreich ein Anruf erstellt.',
          success: true
        });
        return call;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  getPhoneCalls(): Observable<Call[]> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.get(environment.api + '/back/phone_call', {headers});
    return observable.pipe(
      map((rawCall: any) => {
        const objects = CallFactory.jsonFactory(rawCall.phone_calls);
        return objects;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    );
  }

  updatePhoneCall(call: Call) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    let body = call.getJSON();
    const observable = this.http.put(environment.api + '/back/phone_call', body, {headers});
    return observable.pipe(
      map((rawCall: any) => {
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Anruf bearbeitet.',
          success: true
        });
        return CallFactory.jsonFactoryOne(rawCall.phone_call);
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    );
  }

  deletePhoneCall(id: number): Observable<boolean> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.delete(environment.api + '/back/phone_call/' + id, {headers});
    return observable.pipe(
      map((rawCall: any) => {
        this.dService.showNotification({
          title: 'Erfolgreich',
          message: 'Anruf entfernt.',
          success: true
        });
        return true;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    )
  }

  getPhoneCall(call_id: number) {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'JWT ' + localStorage.getItem('token'),
    });
    const observable = this.http.get(environment.api + '/back/phone_call/' + call_id, {headers});
    return observable.pipe(
      map((rawCall: any) => {
        const call = CallFactory.jsonFactoryOne(rawCall.phone_call);
        return call;
      }),catchError(error => {
        if(error.status == 403){
          this.lService.logout();
        } else {
          this.dService.handleError(error);
        }
        return [];
      })
    );
  }


}
